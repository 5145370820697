.card {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 3px 3px 0 rgba(162, 162, 162, 0.5);
  border-top: solid 4px rgba(162, 162, 162, 0.5);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: 1100px) {
    .card {
      &__footer {
        padding: 10px;

        button {
          margin: 0 5px;
        }
      }
    }
  }
  @media only screen and (max-width: 800px) {
    min-width: 90%;
    margin: 0 auto;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    flex: 1;

    &__title {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;

      div:first-of-type {
        display: flex;
        align-items: center;
        justify-content: flex-start;


        span {
          margin-left: 5px;
          white-space: nowrap;
        }
      }

      &--name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
      }

      &--status {
        font-size: 16px;
        font-weight: lighter;
      }

      &--assignee {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        padding: 2px 0;
      }
    }
  }

  &__header-public {
    background-color: rgba(247, 250, 233, 0.712);
  }

  &__progress {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 15px 15px 0;

    &--no-padding-horizontal {
      padding: 15px 0 0;
    }

    &__info {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &--label {
        font-size: 13px;
      }

      &--name {
        font-weight: 700;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.8);
      }
    }

    &__bar {
      flex: 1;
      display: flex;
      flex-direction: column;

      span {
        padding: 2px 0;
        font-size: 13px;
      }
    }
  }

  &__footer {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 15px 5px;
    margin-top: 15px;

    button {
      flex: 1 1 auto;
      margin: 0 10px;
      padding: 5px;
    }
  }
}
