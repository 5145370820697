html::-webkit-scrollbar {
    display: none;
}

html {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

body {
    margin: 0;
    padding: 0;
    background-color: #F7F8F9;
}

.no-data-found {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 35px 0;
    span {
        text-align: center;
        color: #777;
    }
}

.MuiInput-root {
    .MuiSelect-select:focus,
    .MuiInput-input:focus {
        background-color: transparent;
    }
}

.messages {
    &--error {
        font-size: 14px;
        font-weight: 700;
        margin-left: 10px;
        color: red;
    }
}