.user-details {
  margin: 90px auto;
  max-width: 1400px;
  padding: 10px 30px;

  @media only screen and (max-width: 767px) {
    margin: 70px auto;
  }

  &__export {
    margin: 15px 0;
    float: right;
  }

  &__table {
    width: 100%;

    span {
      padding: 5px;

      @media only screen and (max-width: 767px) {
        padding: 3px;
      }
    }

    td {
      overflow: hidden;
      padding: 17px;

      &:first-child p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  &__name {
    margin: 5px 0;
  }

  &__progress {
    margin-top: 30px;

    &__bars {
      width: 80%;
      min-width: 200px;
      max-width: 500px;
      border-radius: 5px;
      padding: 30px 20px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

      .card__progress__bar {
        margin-bottom: 20px;
      }
    }
  }

  &__edit {
    button {
      cursor: pointer;
      padding: 10px;
      width: 100%;
      background: #004c7c;
      border: 0;
      color: white;
      font-size: 16px;
      font-weight: 500;
      border-radius: 5px;

      &:hover {
        background: #004c9c;
      }
    }

    &--modal {
      min-width: 500px;

      @media screen and (max-width: 668px) {
        min-width: 200px;
        max-width: 90vw;
      }

      .error {
        color: red;
        text-align: center;
        width: 100%;
      }

      .manager {
        display: flex;
        align-items: center;
        max-width: 90vw;
        grid-gap: 20px;
        margin-bottom: 10px;
      }

      .teams {
        display: flex;
        flex-direction: column;
        max-width: 90%;

        &__list {
          background: #f0f0f0;
          border-radius: 5px;
          padding: 5px;
          margin: 5px 0;
          max-width: 200px;

          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }

        &__select {
          min-width: 300px;
          max-width: 80vw;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          margin-top: 10px;

          div {
            min-width: 200px;
            max-width: 90%;
            max-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .evaluation {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}