.form {
    &__actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        button {
            min-width: 100px;
        }
        button:not(:last-of-type) {
            margin: 0 15px;
        }
    }
}

@media only screen and (max-width: 772px) {
    .form {
        &__actions {
            justify-content: center;
        }
    }
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}