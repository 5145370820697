@use 'src/assets/styles/variables' as generalStyle;
@import 'src/assets/styles/_modal-styles';

.center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rbc-overlay {
    max-height: 192px;
    border-radius: 6px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 2px;
        background-color: #e6e6e6;
        border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background: #a1a0a0;
        border-radius: 12px;
    }
    &-header {
        font-family: generalStyle.$fontFamily; 
        font-size: 15px;
        padding: 5px 10px;
        border: none;
        text-align: center;
    }
}

.custom-toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .tb-text {
        font-family: generalStyle.$fontFamily; 
        font-size: 24px;
        line-height: 35px;
        margin-left: 5px;
    }
    .tb-buttons {
        display: flex;
        margin-left: 0;
        align-items: center;

        > :nth-child(2) {
            margin-left: -5px;
        }
    }

    .p-button.this-month-button {
        margin-left: auto;
        padding: 12px;
        border: transparent;
        font-family: generalStyle.$fontFamily;   
        font-size: 16px;
        line-height: 22px;
        color: #262626;
        background-color: #e7e9eb;
        border-radius: 6px;
        box-shadow: none;
        &:hover {
            background-color: #d1d5db;
            color: #262626;
        }
    }

    .p-button.p-button-icon-only {
        background-color: transparent;
        width: 30px;
        height: 30px;
        border: transparent;
        border-radius: 50%;
        box-shadow: none;
        &:hover {
            background-color: #d1d5db;
        }

        span {
            color: #262626;
            font-size: 22px;
        } 
    }

   
}

.custom-next-button, .custom-back-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    border-color: transparent;
    width: 30px;
    height: 30px;
    &:hover {
        background-color: #d1d5db;
        > svg path {
            stroke: #262626;
        }

    }
}



.holidays-page {
    @extend .center-flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    .holidays-container {
        background-color: generalStyle.$backgroundColor;
        position: relative;
        max-width: 1400px;
        height: 100%;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        width: 100vw;

        .holidays {
            &__sideMenu .container {
                margin: 7rem 59px 0 0;
                @media only screen and (min-width: 769px) and (max-width: 1150px) {
                    margin: 7rem 25px 0 0;
                }
            }
            &__content {
                margin: 5.3rem 0 0 0;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                width: 100vw;
                padding-bottom: 3rem;

                @media only screen and (max-width: 768px) {
                    margin: 3.3rem 0 0 0;
                }

                .header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    @media only screen and (max-width: 425px) {
                        flex-direction: column;
                    }

                    @media only screen and (max-width: 768px) {
                        flex-wrap: wrap;
                        flex: none;
                        justify-content: space-between;
                    }

                    .flex-1 {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex: none !important;
                        @media only screen and (min-width: 425px) and (max-width: 768px) {
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        p {
                            font-family: generalStyle.$fontFamily;
                            font-size: 30px;
                            color: generalStyle.$textColor;
                            line-height: 34px;
                            margin-right: 20px;

                            @media only screen and (max-width: 768px) {
                                font-size: 18px;
                            }
                        }

                        .teams-dropdown.p-multiselect {
                            border-radius: 6px;
                            border-color: #fff;
                            height: 40px;
                            width: 225px;
                            display: flex;
                            align-items: center;

                            @media only screen and (max-width: 768px) {
                                height: 30px;
                            }

                            &:not(.p-disabled).p-focus {
                                outline: 0 none;
                                outline-offset: 0;
                                box-shadow: none;
                                border-color: generalStyle.$bitstoneRed;
                            }

                            &:not(.p-disabled):hover {
                                border-color: generalStyle.$bitstoneRed;
                                box-shadow: 0 0 0 0 transparent;
                            }

                            .p-multiselect-label {
                                color: generalStyle.$labelTextColor;
                                font-family: generalStyle.$fontFamily;
                                font-size: generalStyle.$textFontSize;
                                padding-left: 11px;
                                align-self: center;

                                &.p-placeholder {
                                    color: generalStyle.$labelTextColor;
                                    font-family: generalStyle.$fontFamily;
                                    font-size: generalStyle.$textFontSize;
                                    padding-left: 11px;
                                }
                            }
                        }
                    }

                    .flex-2 {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        @media only screen and (max-width: 425px) {
                            margin-top: -20px;
                        }

                        p {
                            color: generalStyle.$bitstoneRed;
                            font-family: generalStyle.$fontFamily;
                            font-size: 16px;
                            margin-right: 20px;

                            @media only screen and (max-width: 425px) {
                                font-size: 14px;
                            }
    
                        }

                        .go-to-holidays-app {
                            color: transparent;
                            button {
                                font-size: 16px;
                                border-radius: 6px;
                                width: 170px;
                            }

                            @media only screen and (max-width: 768px) {
                                button {
                                    font-size: 14px;
                                    background-color: transparent;
                                    border: transparent;
                                    color: generalStyle.$bitstoneRed;

                                    span svg path {
                                        fill: generalStyle.$bitstoneRed;
                                    }
                                }
                            }
                        }
                    }
                }

                .calendar {
                    overflow: auto;
                    padding-right: 3px;
                    @include verticalScrollbar;
                    height: 700px;
                    
                    @media only screen and (max-width: 425px) {
                        height: 500px;
                    }
                    .calendar-container {
                        .rbc-toolbar {
                            flex-direction: row-reverse;
                            @media only screen and (max-width: 768px) {
                                flex-direction: column;
                            }

                            .rbc-btn-group>button {
                                border: none;
                                font-family: generalStyle.$fontFamily;
                                color: generalStyle.$textColor;
                                box-shadow: none;
                            }

                            .rbc-toolbar-label {
                                font-family: generalStyle.$fontFamily;
                                font-size: 24px;
                                color: generalStyle.$textColor;
                                line-height: 34px;
                                text-align: start;
                                @media only screen and (max-width: 768px) {
                                    font-size: 18px;
                                }
                            }
                        }

                        .rbc-header {
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: 100;

                            span {
                                font-size: 14px;
                                color: generalStyle.$labelTextColor;
                                text-transform: uppercase;
                                font-family: generalStyle.$fontFamily;
                            }
                        }

                        .rbc-month-row {
                            background: #fff;
                            .rbc-date-cell.rbc-now.rbc-current .rbc-button-link {
                                font-weight: 600;
                            }

                            .rbc-button-link {
                                font-family: generalStyle.$fontFamily;
                            }

                            .rbc-date-cell {
                                text-align: center;
                                a {
                                    font-family: generalStyle.$fontFamily;
                                }
                            }

                            .rbc-off-range-bg {
                                background: #f7f8f9;
                                color: generalStyle.$labelTextColor;
                            }

                            .rbc-row-segment {
                                font-size: 12px;
                                font-family: generalStyle.$fontFamily;
                            }

                            .rbc-today {
                                background-color: #eee;
                            }
                        }
                    }
                }

                .legenda {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-top: 1rem;

                    .each-type {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        margin-right: 30px;

                        .circle {
                            width           : 15px;
                            height          : 15px;
                            border-radius    : 50%;
                            margin-right     : 10px;
                        }
                        span {
                            font-family: generalStyle.$fontFamily;
                            color: generalStyle.$textColor;

                        }
                    }
                }
            }
        }
    }
}