@use 'src/assets/styles/variables' as generalStyle;
@import 'src/assets/styles/_modal-styles';

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  
    .loader {
      box-sizing: border-box;
      position: relative;
      width: 48px;
      height: 48px;
      animation: spin 1s linear infinite;
    }
  
    .loader:after,
    .loader:before {
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      border-radius: 50%;
      background: #FF3D00;
      animation: spin 1s linear infinite;
      transform-origin: 0px 100%;
    }
  
    .loader:before {
      transform-origin: 0 50%;
      background: #fff;
    }
  
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }

.center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.timetracking-page {
    @extend .center-flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    .timetracking-container {
        background-color: generalStyle.$backgroundColor;
        position: relative;
        max-width: 1400px;
        height: 100%;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        width: 100vw;

        .noinfo-bar {
            display: flex;
            flex-direction: column;
            overflow: auto;
            width: 100vw;
            .no-tasks {
                margin-top: 7rem;
                font-family: generalStyle.$fontFamily;
                font-size: 18px;
                color: generalStyle.$textColor;
                background-color: #e7e9eb;
                border-radius: 6px;
                cursor: default;
                padding: 15px;
            }
        }

    

        .timetracking {
            &__sideMenu .container {
                margin: 7rem 59px 0 0;
                @media only screen and (min-width: 769px) and (max-width: 1150px) {
                    margin: 7rem 25px 0 0;
                }
            }
            &__content {
                margin: 7rem 0 0 0;
                display: flex;
                flex-direction: column;
                overflow: auto;
                width: 100vw;
                padding-bottom: 3rem;

                @media only screen and (max-width: 768px) {
                    margin: 5.3rem 0 0 0;
                }

                .info-bar {
                    background-color: #e7e9eb;
                    border-radius: 6px;
                    cursor: default;

                    p,
                    b {
                        margin-left: 10px;
                        font-family: generalStyle.$fontFamily;
                        font-size: 15px;
                        color: generalStyle.$textColor;
                    }
                }

                .header {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .buttons {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        max-width: 4rem;

                        > :nth-child(2) {
                            margin-left: -5px;
                        }

                        .action-btn {
                            background-color: transparent;
                            border: transparent;
                            box-shadow: none;
                            border-radius: 50%;
                            width: 30px;
                            height: 30px;

                            &:hover {
                                background-color: #D1D5DB;
                            }

                            span {
                                color: #262626;
                                font-size: 22px;
                            }
                        }
                    }

                    .date-time {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        .date {
                            font-family: generalStyle.$fontFamily;
                            font-size: 24px;
                            color: generalStyle.$textColor;
                            line-height: 34px;
                            margin-left: 5px;
                            @media only screen and (max-width: 768px) {
                                font-size: 18px;
                            } 
                        }
                        .time {
                            font-family: generalStyle.$fontFamily;
                            font-size: 24px;
                            color: generalStyle.$bitstoneRed;
                            font-weight: bold;
                            line-height: 34px;
                            @media only screen and (max-width: 768px) {
                                font-size: 18px;
                            } 
                        }
                    }
                }

                .tasks {
                    padding-right: 10px;
                    overflow: auto;
                    padding-bottom: 2rem;
                    @include verticalScrollbar;
                    .grouped-tasks {
                        label {
                            font-family: generalStyle.$fontFamily;
                            font-size: 16px;
                            color: generalStyle.$textColor;
                            font-weight: bold;
                            line-height: 24px;
                            margin-bottom: 8px;
                            display: inline-block;
                        }
                        .task {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            background-color: #fff;
                            margin: 2px 0;
                            border-radius: 4px;
                            p {
                                margin-left: 10px;
                                font-family: generalStyle.$fontFamily;
                                font-size: 16px;
                                color: generalStyle.$textColor;
                                background-color: #fff;
                                border-radius: 6px;
                                padding: 14px;
                                margin: 2px 0;
                                cursor: default;
                            }
                        }
                        .total {
                            width: 98%;
                            text-align: end;
                            font-family: generalStyle.$fontFamily;
                            font-size: 16px;
                            color: generalStyle.$textColor;
                            font-weight: bold;
                            margin: 10px 0 0 0;
                        }
                    }
                }
            }
        }
    }
}