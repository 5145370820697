.completion-approval {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;

    &__text {
        max-width: 1200px;
        margin-bottom: 100px;
    }

    &__actions {
        button {
            margin: 10px;
        }
    }

    &__message {
        font-size: 20px;
    }

    &__error {
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}