.main-div {
    background-color: #eef5f9;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.container-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 100%;
    height: 100vh;
    min-height: 95vh;

    .image-logo {
        width: 200px;
        height: 200px;
        margin-bottom: 25px;

        @media only screen and (max-width: 772px) {
          height: 100px;
          width: 100px;

        }
    }

    & .title {
        font-size: 1.5em;
        font-weight: bold;
        text-transform: uppercase;
        margin: 25px 0;

        @media only screen and (max-width: 772px) {
          font-size: 1.2em;
        }
    }
}

.button-class{
  width: 330px;
}

.title {
    text-align: center;
}

.logged-in{
    &-container-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 100%;
      min-height: 60vh;
    }

    &-container-list{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px;
        padding: 10px;
        width: 50%;
        border-bottom: 2px solid #f1ebeb;
      }
    }

    &-container-button{
      width: 330px;

      button{
        width: 100%;
      }
    }

    &-stepper-style{
      margin: 20px;
    }

    &-input-style{
      width: 100%;
    }

    &-container-style{
      margin-bottom: 30px;
      width: 330px;
    }
  }
