@use 'src/assets/styles/variables' as generalStyle;


// Breakpoints
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;

.hidden-xs {
  @media only screen and (max-width: $breakpoint-sm) {
    display: none;
  }
}

.team {
  display: flex;
  justify-content: center;
  width: 100%;

  &-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
  }
}

.team__main {
  display: flex;
  flex-direction: column;

  &-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin: 6.3rem 0 0 0;
    align-items: center;
    height: 60px;

    .text-container {
      p {
        font-size: 30px;
        font-family: generalStyle.$fontFamily;
        color: generalStyle.$textColor;
      }
    }

    .buttons-container {
      position: relative;

      .buttons {
        display: flex;
        flex-direction: row;

        button {
          span {
            display: inherit;
            margin-right: 8px;
            margin-left: 0px;
          }
        }
      }
    }
  }

  &-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: opacity 1s, visibility 1s;
     margin-top: 1rem;
  
    &.hidden {
      visibility: hidden;
      opacity: 0;
    }
  }

  &-users {
    width: 100%;
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
    grid-gap: 53px;
    padding-bottom: 5rem;
  }

  .go-to-top-button.p-button {
    position: fixed;
    bottom: 75px;
    left: 90%;
    z-index: 999;
    background-color: generalStyle.$bitstoneRed;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 35px;
    height: 35px;

    span {
      color: #fff;
    }

    &:hover {
      background-color: #D21219;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none,

    }
  }
}

@media only screen and (max-width: 425px) {
  .team {
    overflow-x: hidden;

    .team-container {
      padding: 0;
      width: 100vw;
    }

    .team__main {
      display: flex;
      flex-direction: column;

      &-top {
        flex-direction: column;
        margin: 3rem 0 0 0;
        height: fit-content;
        padding: 0;

        .text-container p {
          font-size: 25px;
          margin: 23px 0 14px 0;
        }

        .buttons-container {
          .buttons {
            width: 100%;
            .export-btn,
            .add-btn {
              width: 100px;
              height: 30px;
              font-size: 13px;
              line-height: 10px;
              justify-content: center;
              border: transparent;

              span {
                margin: 0;
              }

              svg {
                display: none;
              }
            }
          }
        }
      }

      &-filters {
        margin-left: 0;
        padding-left: 0;
      }

      &-users {
        // justify-items: center;
        // grid-template-columns: 1fr;
        // row-gap: 20px;
        gap: 20px;
        padding: 0 15px 3rem 15px;
        width: 100vw;
      }

      .go-to-top-button.p-button {
        bottom: 50px;
        left: 87%;
        width: 35px;
        height: 35px;
        border-radius: 5px;

        span {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (min-width: 425px) and (max-width: 600px) {
  .team {
    // overflow-x: hidden;

    .team-container {
      padding: 0;
      // width: auto;
    }

    .team__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      &-top {
        flex-direction: column;
        margin: 3rem 0 0 0;
        height: fit-content;
        padding: 0;

        .text-container p {
          font-size: 25px;
          margin: 23px 0 14px 0;
        }

        .buttons-container {
          .buttons {
            width: 100%;
            .export-btn,
            .add-btn {
              width: 100px;
              height: 30px;
              font-size: 13px;
              line-height: 10px;
              justify-content: center;
              border: transparent;

              span {
                margin: 0;
              }

              svg {
                display: none;
              }
            }
          }
        }
      }

      &-filters {
        margin-left: 0;
        padding-left: 0;
      }

      &-users {
        // justify-items: center;
        // grid-template-columns: 1fr;
        gap: 20px;
        padding: 0 15px 3rem 15px;
      }

      .go-to-top-button.p-button {
        bottom: 50px;
        left: 87%;
        width: 35px;
        height: 35px;
        border-radius: 5px;

        span {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .team {
    overflow-x: hidden;

    .team-container {
      padding: 0;
    }

    .team__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: 100%;
      &-top {
        flex-direction: row;
        margin: 4rem 0 -20px 0;
        height: fit-content;
        padding: 0 20px;
        width: 100vw;

        .text-container p {
          font-size: 25px;
        }

        .buttons-container {
          .buttons {
            width: 100%;
            .export-btn,
            .add-btn {
              width: 100px;
              height: 30px;
              font-size: 13px;
              line-height: 10px;
              justify-content: center;
              border: transparent;

              span {
                margin: 0;
              }

              svg {
                display: none;
              }
            }
          }
        }
      }

      &-filters {
        margin-left: 0;
        width: 100vw;
        justify-content: space-between;
        padding-left: 0;
      }

      &-users {
        // justify-items: center;
        // grid-template-columns: 1fr 1fr;
        gap: 30px;
        padding: 10px 20px;
      }

      .go-to-top-button.p-button {
        bottom: 50px;
        left: 90%;
        width: 35px;
        height: 35px;
        border-radius: 5px;

        span {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1150px) {
  .team {
    overflow-x: hidden;

    .team-container {
      width: 100%;
      position: absolute;
    }

    .team__main {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-basis: 100%;
      &-top {
        flex-direction: row;
        margin: 5.3rem 0 0 0;
        height: fit-content;
        padding: 0;

        .text-container p {
          font-size: 25px;
        }

        .buttons-container {
          .buttons {
            width: 100%;
            .export-btn,
            .add-btn {
              width: 100px;
              height: 30px;
              font-size: 13px;
              line-height: 10px;
              justify-content: center;
              border: transparent;

              span {
                margin: 0;
              }

              svg {
                display: none;
              }
            }
          }
        }
      }

      &-filters {
        margin-left: 0;
        justify-content: space-between;
        padding: 0;
      }

      &-users {
      //  display: flex;
      //  flex-wrap: wrap;
      //  padding: 0;
      //  justify-content: flex-start;
      //  row-gap: 27px;
      }

      .go-to-top-button.p-button {
        bottom: 50px;
        left: 90%;
        width: 35px;
        height: 35px;
        border-radius: 5px;

        span {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1151px) and (max-width: 1440px) {
  .team {
    overflow-x: hidden;

    .team-container {
      width: 100%;
      position: absolute;
    }

    .team__main {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &-top {
        flex-direction: row;
        margin: 5.3rem 0 0 0;
        height: fit-content;

        .text-container p {
          font-size: 30px;
        }

        .buttons-container {
          .buttons {
            width: 100%;
            .export-btn,
            .add-btn {
              width: 165px;
              height: 40px;
              font-size: 14px;
              line-height: 10px;
              justify-content: center;
              border: transparent;
            }
          }
        }
      }

      &-filters {
        margin-left: 0;
        justify-content: space-between;
      }

      &-users {
       padding: 0;
      //  row-gap: 27px;
       padding-bottom: 4rem;
      }

      .go-to-top-button.p-button {
        bottom: 50px;
        left: 97vw;
        width: 35px;
        height: 35px;
        border-radius: 5px;

        span {
          font-size: 14px;
        }
      }
    }
  }
}