.objectives {
  &-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__public {
      button {
        float: right;
        margin: 10px 20px 0 0;
      }
    }
  }

  /*
   Please keep the grid format because flexbox is not suitable for this template.
   It is easier to create a responsive design and it keeps the
   same size for each card in the current list
  */

  &-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: space-between;
    grid-gap: 10px;
    padding-top: 10px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    @media only screen and (max-width: 1100px) {
      grid-template-columns: repeat(2, minmax(350px, 1fr));
    }

    @media only screen and (max-width: 800px) {
      grid-template-columns: 1fr;
      width: 100%;
      justify-content: center;
   }

    &__item {
      max-width: 90vw;
      margin: 0 10px 20px;

      @media only screen and (max-width: 800px) {
        margin: 0 auto;
        width: 100%;
     }
    }
  }

  &-pagination {
    margin-top: 20px;
    margin-bottom: 100px;
  }
}
