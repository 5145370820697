@use 'src/assets/styles/variables' as generalStyle;
@import 'src/assets/styles/_data-table-styles';
@import 'src/assets/styles/_modal-styles';
@import 'src/assets/styles/_general-styles';

.datatable-crud-teams {
    .teams-container {
        @include dataTableStyles;
    }
}

.teamsDialog.p-dialog, .team-modals.p-dialog {
    @include modalCommonStyles;

    .field {
        display: flex;
        flex-direction: column;

        .p-inputtext {
            border: 1px solid #fff;
            height: 40px;
            font-size: generalStyle.$textFontSize;
            color: generalStyle.$textColor;
            font-family: generalStyle.$fontFamily;
            box-shadow: none;

            @include hoverAndFocusExternalComponents;
        }

        label {
            color: generalStyle.$labelTextColor;
            font-size: generalStyle.$labelFontSize;
            font-family: generalStyle.$fontFamily;
        }

        .p-dropdown {
            @include displayFlexRowStyles;
            border-color: #fff;
            border: none;

            .p-dropdown-label {
                color: generalStyle.$textColor;
                padding: 10px 10px;
                border: none !important;
            }

            @include hoverAndFocusExternalComponents;
            .pi-chevron-down:before {
                color: generalStyle.$textColor;
            }
        }

        .p-inputtextarea {
            height: auto;
            resize: vertical;
            -ms-overflow-style: none;
            scrollbar-width: none;
            overflow-y: auto;
            overflow-x: hidden;
            height: 75px;
            @include verticalScrollbar;
            @include hoverAndFocus;
        }
    }
}

@media only screen and (max-width: 425px) {
    .datatable-crud-teams {
        .teams-container {
            @include smallResolutionStyles;
        }
    }
}

@media only screen and (min-width: 425px) and (max-width: 958px) {
    @include datatableCommonStyles;
}

@media only screen and (min-width: 958px) and (max-width: 1440px) {
    @include datatableCommonStyles;

    .datatable-crud-teams {
        .teams-container {
            .p-datatable table {
                .p-column-title {
                    font-size: 14px;
                }
            }
        }
    }
}