.overview-card {
  transition: box-shadow 0.2s;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.15);

  &__name:hover {
    text-decoration: underline;
  }

  &__team {
    display: grid;
    grid-template-columns: repeat(3, auto);

    p {
      font-size: 13px;
    }
  }
}

.user-details .overview-card__name:hover {
  text-decoration: none;
}

.overview-card:hover {
  cursor: pointer;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.4);
}

.user-details .overview-card:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.15);
  cursor: auto;
}

.my-card {
  border-top: solid 3px #004c7c;
}
