.objective {
  margin: 80px auto 50px;
  padding: 20px;
  max-width: 1400px;
  word-break: break-word;

  &--details {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;

    &--link {
      display: block;
      position: absolute;
      bottom: 0;
      left: 30px;
      background: red;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      line-height: 37.5px;
      cursor: pointer;
      text-align: center;
      color: #FFF;
      font-size: 40px;
      font-weight: bolder;
    }

    &--wrapper {
      flex: 2;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    &--container {
      padding: 0 20px;
      border-top: 4px solid red;
      border-radius: 3px;
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      min-height: 450px;

      p {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        font-weight: 700;
        color: #555;

        span {
          font-weight: normal;
          color: #111;
          font-size: 14px;
        }
      }
    }

    &--header {

      p#title {
        font-size: 18px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-left: 0;
        align-items: center;

        span {
          margin-right: 20px;
          width: 21px;
          height: 21px;
        }
      }

      & .persons {
        margin-top: -1em;
        display: grid;
        grid-template-columns: repeat(3, minmax(auto, 200px));
        grid-template-rows: auto;

        p {
          font-size: 16px;

          span {
            font-size: 14px;
            word-break: break-word;
          }
        }
      }

      &::after {
        display: block;
        content: "";
        width: 100%;
        margin: 5px 0;
        border-top: 1px solid #cdcdcd;
      }
    }

    &--dates {
      display: grid;
      grid-template-columns: repeat(3, minmax(auto, 200px));
      grid-template-rows: auto;
    }

    &--info {
      display: grid;
      grid-template-columns: repeat(3, minmax(auto, 200px));
      grid-template-rows: auto;
    }

    &--actions {
      border-top: 1px solid #cdcdcd;
      width: 100%;
      margin-top: auto;
      padding: 15px 0;
      display: flex;
      justify-content: flex-end;
      align-content: flex-end;

      button {
        margin-left: 25px;
        height: 40px;
      }
    }

    &--key-result {
      flex: 1;
      padding-left: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      &__card {
        width: 100%;
        border-top: 4px solid;
        border-radius: 3px;
        max-width: 100%;
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
        padding: 0 20px;
        min-height: 200px;

        &:not(:first-of-type) {
          margin: 1.5em 0;
        }

        &__info {
          display: grid;
          grid-template-columns: repeat(2, minmax(auto, 200px));
          grid-template-rows: auto;
        }

        h3 {
          margin: 18px 0;
          color: #555;
          white-space: normal;
          font-size: 18px;

          &:after {
            display: block;
            content: "";
            width: 100%;
            margin: 15px 0;
            border-top: 1px solid #cdcdcd;
          }
        }

        p {
          margin: 0 0 15px;
          display: flex;
          flex-direction: column;
          font-size: 15px;
          font-weight: 700;
          color: #555;
          padding: 2px;

          span {
            font-weight: normal;
            color: #111;
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &--actions {
          width: 100%;
          margin-top: auto;
          padding: 15px 0;
          display: flex;
          justify-content: flex-end;
          align-content: flex-end;
          border-top: 1px solid #cdcdcd;

          button {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .objective {
    margin-top: 75px;
    margin-bottom: 30px;

    &--details {
      width: 100%;
      flex-direction: column;

      &--container {
        min-height: 400px;
        max-width: 100%;
        width: 100%;
      }

      &--key-result {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-left: 0;

        &__card {
          margin: 10px 0;

          &:not(:first-of-type) {
            margin: 10px;
          }

          p {
            display: flex;
            flex-direction: column;
            font-size: 15px;
            font-weight: 700;
            color: #555;

            span {
              font-weight: normal;
              color: #111;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .objective {
    margin-top: 75px;

    button {
      font-size: 14px;
    }

    &--details {
      flex-direction: column;
      align-items: stretch;

      &--container {
        min-height: 400px;

        p {
          font-size: 14px;

          span {
            font-size: 12px;
          }
        }
      }

      &--header {
        & .persons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }

      &--dates {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        p:first-child {
          display: none;
        }
      }

      &--info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &--actions {
        justify-content: space-between;

        button {
          margin-left: 0;

          &:first-of-type {
            display: none;
          }
        }
      }

      &--key-result {
        padding-left: 0;

        &__card {
          margin: 0 0 20px;
          width: 100%;

          p {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            font-weight: 700;
            color: #555;

            span {
              font-weight: normal;
              color: #111;
              font-size: 12px;
            }
          }

          &--actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            button {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
