@use 'src/assets/styles/variables' as generalStyle;

.container {
    width: 310px;
    margin: 7rem 4rem 0 0;
    .sideMenu {
        height: calc(100vh - 110px);
        position: fixed;
        background-color: #262525;
        border-radius: 6px;
        padding: 1rem;
    
        &-option {
            text-decoration: none;
            height: 60px;
        }
        a {
            text-decoration: none;
            .obj-btn {
                svg {
                    margin-right: 13px;
                }
            }
        }
       
    }
    
    .active {
        button {
            color: #fff;
            background-color: generalStyle.$bitstoneRed;
            border-color:generalStyle.$bitstoneRed;
            box-shadow: none;
    
            svg > path {
                fill: #fff;
            }
    
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 1150px)  {
    .container {
        width: 57px;
        .sideMenu {
            width: 75px;
        
            &-option {
                text-decoration: none;
                height: 46px;
            }
    
            button {
                width: 42px;
                height: 40px;
                font-size: 0px;
                min-width: 0;
                .css-9tj150-MuiButton-endIcon {
                    margin-left: 20px;
                }
            }
        }
        
        .active {
            button {
                color: #fff;
                background-color: generalStyle.$bitstoneRed;
                border-color:generalStyle.$bitstoneRed;
                box-shadow: none;
                font-size: 0px;
        
                svg > path {
                    fill: #fff;
                }
        
            }
        }
    }
}


@media only screen and (min-width: 1151px) and (max-width: 1440px) { 
    .container {
        .sideMenu {
            &-option {
                text-decoration: none;
                width: 235px;
            }
    
            button {
                .css-9tj150-MuiButton-endIcon {
                    margin-left: 20px;
                }
            }
        }

        .active {
            button {
                color: #fff;
                background-color: generalStyle.$bitstoneRed;
                border-color:generalStyle.$bitstoneRed;
                box-shadow: none;
        
                svg > path {
                    fill: #fff;
                }
        
            }
        }
    }
}
