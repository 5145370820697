@use 'src/assets/styles/variables' as generalStyle;

@mixin smallResolutionStyles {
    .p-datatable .p-datatable-header .table-header {
        .p-input-icon-left>i {
            margin-top: -9px;
            font-size: 13px;
        }

        .p-inputtext {
            height: 30px;
        }

        button {
            background-color: #fff;
            border: none;
            display: flex;
            justify-content: center;
            width: 35px;
            height: 35px;

            .p-button-icon-left {
                margin: 0;
                color: generalStyle.$bitstoneRed;
            }

            .p-button-label {
                display: none;
            }
        }
    }

    .p-datatable table {
        width: 100vw;

        .p-datatable-thead>tr>th {
            border: none;
        }

        .p-datatable-tbody>tr>td {
            font-size: 15px;
            padding: 6px 20px;
            font-family: generalStyle.$fontFamily;

            .p-column-title {
                font-size: 14px;
                margin-right: 30px;
                font-family: generalStyle.$fontFamily;
            }

            .gap-2 {
                span {
                    font-size: 15px;
                    font-family: generalStyle.$fontFamily;
                }
            }
        }

        .p-datatable-tbody>tr>td.actions {
            justify-content: flex-end;
            padding-bottom: 10px;

            button {
                height: 30px;
                width: 30px;

                span {
                    font-size: 14px;
                }
            }
        }
    }
}

@mixin dataTableStyles {
    .p-datatable .p-datatable-header {
        border: none;
        padding: 1rem 1rem;

        .table-header {
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            .p-inputtext {
                border: 1px solid #fff;
                height: 40px;
                font-size: generalStyle.$textFontSize;
                color: generalStyle.$textColor;
                font-family: generalStyle.$fontFamily;

                &:enabled:hover {
                    border: 1px solid generalStyle.$bitstoneRed;
                }

                &:enabled:focus {
                    box-shadow: 0 0 0 0 transparent;
                    outline: none;
                    border: 1px solid generalStyle.$bitstoneRed;
                }
            }

            button {
                background-color: #fff;
                border: none;
                display: flex;
                justify-content: center;
                width: 125px;
                height: 40px;
                box-shadow: none;

                .p-button-icon-left {
                    margin: 0;
                    color: generalStyle.$bitstoneRed;
                }

                .p-button-label {
                    color: generalStyle.$bitstoneRed;
                    font-family: generalStyle.$fontFamily;
                    font-size: 16px;
                }
            }
        }
    }

    .p-datatable table {
        width: 100%;
        table-layout: fixed;

        .p-datatable-tbody>tr>td {
            padding: 6px 20px;
            font-family: generalStyle.$fontFamily;
            .gap-2 span {
                font-size: 16px;
                font-family: generalStyle.$fontFamily;
            }
        }
        .p-datatable-tbody>tr>td.actions {
            justify-content: flex-end;
            padding-bottom: 10px;

            button {
                height: 40px;
                width: 40px;
            }
        }
    }

    .p-datatable .p-sortable-column {
        background: #f8f9fa;
        color: generalStyle.$textColor;
        box-shadow: none;

        .p-highlight {
            background: #EEF2FF;
            color: generalStyle.$textColor;
            box-shadow: none;
            border: none;
        }
    }

    .p-datatable .p-paginator-bottom {
        border-radius: 0 0 6px 6px;

        .p-dropdown {
            margin-left: 0.5rem;
            height: 3rem;
            border: 1px solid generalStyle.$labelTextColor;

            &:not(.p-disabled).p-focus {
                box-shadow: none;
                border: 1px solid generalStyle.$bitstoneRed;
            }

            &:not(.p-disabled):hover {
                box-shadow: 0 0 0 0 transparent;
                outline: none;
                border: 1px solid generalStyle.$bitstoneRed;
            }
        }
    }
}

@mixin datatableCommonStyles {
    .p-datatable .p-datatable-header .table-header {
        .p-input-icon-left>i {
            margin-top: -9px;
            font-size: 16px;
        }

        .p-inputtext {
            height: 35px;
            font-size: 16px;
            font-family: generalStyle.$fontFamily;
        }

        button {
            background-color: #fff;
            border: none;
            display: flex;
            justify-content: center;
            width: 35px;
            height: 35px;

            .p-button-icon-left {
                margin: 0;
                color: generalStyle.$bitstoneRed;
            }
        }
    }

    .p-datatable {
        overflow: hidden;

        table {
            width: 100%;

            .p-datatable-tbody>tr {
                display: table-row;

                >td {
                    font-size: 16px;
                    padding: 10px 20px;
                    font-family: generalStyle.$fontFamily;
                    text-align: justify;

                    .p-column-title {
                        font-size: 15px;
                        margin-right: 20px;
                        font-family: generalStyle.$fontFamily;

                    }

                    .gap-2 span {
                        font-size: 16px;
                        font-family: generalStyle.$fontFamily;

                    }
                }
            }

            .p-datatable-tbody>tr>td.actions {
                justify-content: flex-end;
                padding-bottom: 10px;

                button {
                    height: 35px;
                    width: 35px;
                
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}