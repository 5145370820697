.container-modal{
  display : flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100vh;
}

.modalDiv{
  background-color: #ffffff;
  padding: 25px;

  Button{
    float: right;
  }
}
