@use 'src/assets/styles/variables' as generalStyle;
@import 'src/assets/styles/_data-table-styles';
@import 'src/assets/styles/_modal-styles';

.datatable-crud-users {
    // padding-bottom: 2rem;
    .users-container {
        @include dataTableStyles;
    }
}


.restoreModal.p-dialog, .deleteModal.p-dialog {
    @include modalCommonStyles;
  
    .pi.pi-times {
      font-size: 16px;
    }
  
    .p-dialog-content {
      padding: 1rem 1.5rem;
  
      .confirmation-content {
        display: flex;
        align-items: center;
      }
    }
  
    .p-dialog-footer {
      height: 50px;
  
      button span {
        font-size: 14px;
      }
    }
  }
  

@media only screen and (max-width: 425px) {
    .datatable-crud-users {
        .users-container {
            @include smallResolutionStyles;
        }
    }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
    @include datatableCommonStyles;
}


@media only screen and (min-width: 768px) and (max-width: 958px) {
    @include datatableCommonStyles;

    .datatable-crud-users {
        .users-container {
            .p-datatable {
                overflow: hidden;

                table {
                    width: 100%;

                    .p-datatable-tbody {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 25px;

                        >tr {
                            border: 1px #f3f3f3 solid;

                            &:nth-child(odd) {
                                border-width: 0 2px 2px 0px;
                            }

                            &:nth-child(even) {
                                border-width: 0 0px 2px 2px;
                            }


                            >td {
                                font-size: 16px;
                                padding: 10px 20px;
                                font-family: generalStyle.$fontFamily;

                                .p-column-title {
                                    font-family: generalStyle.$fontFamily;
                                    font-size: 16px;
                                }

                                .gap-2 {
                                    .custom-name {
                                        font-family: generalStyle.$fontFamily;
                                        font-size: 16px;

                                        img {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 958px) and (max-width: 1440px) {
    @include datatableCommonStyles;

    .datatable-crud-users {
        .users-container {
            .p-datatable table {
                .p-datatable-tbody>tr>td {
                    .gap-2 {
                        img {
                            display: none;
                        }
                    }
                    &.actions button {
                        height: 30px;
                        width: 30px;
                    }
                }
                @media only screen and (min-width: 1220px) and (max-width: 1440px) {
                    width: 100%;

                    .p-datatable-tbody>tr>td {
                        .gap-2 {
                            img {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}