@use 'src/assets/styles/variables' as generalStyle;
@import 'src/assets/styles/_data-table-styles';
@import 'src/assets/styles/_modal-styles';

.datatable-crud-roles {
    .roles-container {
        @include dataTableStyles;
    }
}

.delete-restore-action-modal.p-dialog {
    @include modalCommonStyles;
    .pi.pi-times {
        font-size: 16px;
      }
     .p-dialog-content {
        padding: 1rem 1.5rem;
    
        .confirmation-content {
          display: flex;
          align-items: center;
        }
      }
    
      .p-dialog-footer {
        height: 50px;
    
        button span {
          font-size: 14px;
        }
      }
}

.rolesDialog.p-dialog {
    height: 70%;
    width: 450px;
    @include modalCommonStyles;

    .p-dialog-content {
        &::-webkit-scrollbar {
            width: 3px;
            background-color: #e6e6e6;
            border-radius: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background: generalStyle.$bitstoneRed;
            border-radius: 12px;
        }
    }

    .field {
        display: flex;
        flex-direction: column;

        .p-inputtext {
            border: 1px solid #fff;
            height: 40px;
            font-size: 16px;
            color: generalStyle.$textColor;
            font-family: generalStyle.$fontFamily;
            box-shadow: none;

            @include hoverAndFocusExternalComponents;
        }

        label {
            color: generalStyle.$labelTextColor;
            font-size: generalStyle.$labelFontSize;
            font-family: generalStyle.$fontFamily;
        }

        .field-checkbox {
            margin: 0;

            .label.p-inputtext {
                color: generalStyle.$labelTextColor;
                font-size: generalStyle.$labelFontSize;
                font-family: generalStyle.$fontFamily;
                border: none;
                background-color: transparent;
                cursor: pointer;
                font-size: 16px;

                &:enabled:hover {
                    border: none;
                }

                &:enabled:focus {
                    border: none;
                }
            }
        }

        .p-checkbox .p-checkbox-box {
            border: 1px solid #ced4da;
            border-radius: 4px;
            box-shadow: none;

            .p-checkbox-icon {
                box-shadow: none;
            }

            .p-checkbox.p-checkbox-disabled {
                cursor: not-allowed;
            }
        }

        .p-inputtextarea {
            height: auto;
            resize: vertical;
            -ms-overflow-style: none;
            scrollbar-width: none;
            overflow-y: auto;
            overflow-x: hidden;
            height: 75px;
            @include verticalScrollbar;
            @include hoverAndFocus;
        }
    }
}

@media only screen and (max-width: 425px) {
    .datatable-crud-roles {
        .roles-container {
            @include smallResolutionStyles;
        }
    }
}

@media only screen and (min-width: 425px) and (max-width: 958px) {
    @include datatableCommonStyles;
}

@media only screen and (min-width: 958px) and (max-width: 1440px) {
    @include datatableCommonStyles;
    .datatable-crud-roles {
        .roles-container {
            .p-datatable table {
                .p-column-title {
                    font-size: 14px;
                }
            }
        }
    }
}
