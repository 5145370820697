$bitstoneRed: #ec1c24;
$fontFamily : 'Futura Book',
sans-serif;
$backgroundColor : #f7f8f9;
$textFontSize: 16px;
$labelFontSize: 14px;
$labelTextColor: #7f8692;
$textColor : #262626;
$shadowColor : #7F86920D;
$subTextFontSize: 14px;
$lineColor : #E7E9EB;

