.statistics-wrapper {
    margin-top: 84px;
    border-bottom: solid 1px #ddd;
    .statistics-filters {
        width: 100%;
        padding: 0 0 5px;
        max-width: 1400px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        &__filter {
            width: 130px;
        }
    }
}