$primary-gray-color: rgb(77, 77, 77);
.footer-container {
  background: #000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 500;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  .footer-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 30px;

    .footer-link {
      display: flex;
      align-items: center;
      padding: 5px;
      font-size: 12px;
      color: #fff;

      span {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 350px) {
    .footer-menu {
      display: inline-block;
    }
  }

  a {
    text-decoration: none;
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    color: #fff;
    font-weight: 600;
  }
}
