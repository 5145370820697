.relative {
  & .loader {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;

    background: rgba(0, 0, 0, 0.4);
    z-index: 9999;

    &-spinner {
      position: absolute;
      top: 50%; right: 50%;
      transform: translate(50%,-50%);
    }

  }
}
