@use 'src/assets/styles/variables' as generalStyle;

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    @extend .center-flex;
    height: 100vh;

    p {
        font-size: 30px;
        animation: blink 2s infinite;
        @media only screen and (max-width: 425px) {
            font-size: 25px;
        }
    }
}

.dashboard {
    &-container {
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 20px;
    }

    &__content {
        margin: 80px 0 1rem 0;
        @extend .scrollbar-hidden;

        &-top {
            padding-right: 20px;

            p {
                font-size: 30px;
            }

            &__dropdown .p-multiselect {
                width: 230px;
                border-color: #fff;
                height: 40px;

                &:hover,
                &:not(.p-disabled):hover,
                &:not(.p-disabled).p-focus {
                    border-color: generalStyle.$bitstoneRed;
                    outline: none;
                    box-shadow: none;
                }

                .p-multiselect-label,
                .p-multiselect-label-container {
                    color: generalStyle.$labelTextColor;
                    font-family: generalStyle.$fontFamily;
                    font-size: generalStyle.$textFontSize;
                    padding-left: 11px;
                }
            }
        }

        &-links {
            .go-to-top-button.p-button {
                position: fixed;
                bottom: 42px;
                left: 94vw;
                z-index: 999;
                background-color: generalStyle.$bitstoneRed;
                border: none;
                border-radius: 5px;
                width: 35px;
                height: 35px;

                &:hover,
                &:focus {
                    background-color: #D21219;
                    outline: none;
                    box-shadow: none;
                }

                span {
                    font-size: 14px;
                    color: #fff;
                }
            }

            .p-dashboard {
                margin-bottom: 16px;
                font-size: 20px;
                color: generalStyle.$labelTextColor;
            }

            .each-category-links {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
                grid-gap: 35px;
                margin-bottom: 30px;
                padding-right: 20px;
              }              
        }
    }
}

@media only screen and (max-width: 425px) {
    .dashboard {
        &__content {
            margin-top: 60px;

            &-top {
                margin-left: 0;
                padding: 0 10px;

                p {
                    font-size: 25px;
                }

                &__dropdown .p-multiselect {
                    width: 180px;
                    height: 35px;
                    margin-right: 0;
                }
            }

            &-links {
                margin-top: -18px;

                .go-to-top-button.p-button {
                    bottom: 50px;
                    left: 87%;
                    width: 35px;
                    height: 35px;

                    span {
                        font-size: 14px;
                    }
                }

                .p-dashboard {
                    font-size: 18px;
                    margin-left: 0;
                    text-align: center;
                    padding-left: 0;
                }

                .each-category-links {
                    padding: 0 15px;
                }
            }
        }
    }
}

@media only screen and (min-width: 425px) and (max-width: 769px) {
    .dashboard {
        &__content {
            margin: 3.5rem 0 2rem 0;

            &-top {
                margin-left: 0;
                padding: 0 10px;

                &__dropdown .p-multiselect {
                    height: 35px;
                    margin-right: 0;
                    width: 185px;
                }
            }

            &-links {
                .go-to-top-button.p-button {
                    bottom: 50px;
                    left: 92vw;
                    width: 35px;
                    height: 35px;

                    span {
                        bottom: 52px;
                    }
                }

                .p-dashboard {
                    font-size: 18px;
                    margin-left: 15px;
                    padding: 0;
                }

                .each-category-links {
                    padding: 0 15px;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .dashboard {
        &-container {
            .dashboard__content {
                &-top {
                    margin-left: 0;

                    &__dropdown .p-multiselect {
                        height: 33px;
                        margin-right: 0;
                    }
                }
    
                &-links {
                    .p-dashboard {
                        margin: 10px 0;
                        padding-left: 0;
                    }
    
                    .each-category-links {
                        grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
                        padding-left: 0;
                    }
                }
            }
        }
    }
}


.p-multiselect-items-wrapper {
    &::-webkit-scrollbar {
        width: 2px;
        background-color: #e6e6e6;
        border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background: generalStyle.$bitstoneRed;
        border-radius: 12px;
    }
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    color: generalStyle.$labelTextColor !important;
    font-family: generalStyle.$fontFamily;
    font-size: generalStyle.$textFontSize;
}