@use 'src/assets/styles/variables' as generalStyle;
@import 'src/assets/styles/_modal-styles';
@import 'src/assets/styles/_general-styles';

.userDialog.p-dialog {
    @include modalCommonStyles;
    width: 550px;

    .field {
        .input.p-inputtext {
            box-shadow: none;
            border: 1px solid #fff;
            height: 40px;
            font-size: generalStyle.$textFontSize;
            color: generalStyle.$textColor;
            font-family: generalStyle.$fontFamily;
            @include hoverAndFocusExternalComponents;
        }

        label {
            color: generalStyle.$labelTextColor;
            font-size: generalStyle.$labelFontSize;
            font-family: generalStyle.$fontFamily;
        }
        .p-dropdown {
            @include displayFlexRowStyles;
            box-shadow: none;
            border-color: #fff;

            .p-dropdown-label {
                color: generalStyle.$textColor;
                padding: 10px 10px;
                border: none;
            }

            @include hoverAndFocusExternalComponents;

            .pi-chevron-down:before {
                color: generalStyle.$textColor;
            }
        }
    }

    .double-row {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .field {
            width: 49%;

            .p-dropdown {
                @include displayFlexRowStyles;
                box-shadow: none;
                border-color: #fff;

                .p-dropdown-label {
                    color: generalStyle.$textColor;
                    padding: 10px 10px;
                    border: none;
                }

                @include hoverAndFocusExternalComponents;

                .pi-chevron-down:before {
                    color: generalStyle.$textColor;
                }
            }

            .p-multiselect {
                border-radius: 6px;
                border-color: #fff;
                height: 40px;
                box-shadow: none;

                .p-multiselect-label {
                    color: generalStyle.$textColor;
                    font-size: generalStyle.$textFontSize;
                    font-family: generalStyle.$fontFamily;
                    border: none;
                }

                .p-multiselect-label-container {
                    align-items: center;
                    display: flex;
                }

                @include hoverAndFocusExternalComponents;

                .pi-chevron-down:before {
                    color: generalStyle.$textColor;
                }
            }
        }
    }

    .p-calendar {
        width: 100%;

        .p-inputtext {
            color: generalStyle.$textColor;
            font-size: generalStyle.$textFontSize;
            font-family: generalStyle.$fontFamily;
            border: none;
            box-shadow: none;

            @include hoverAndFocusExternalComponents;
        }

    }

    .p-calendar-w-btn-right .p-datepicker-trigger {
        color: #fff;
        background: generalStyle.$bitstoneRed;
        border: 1px solid generalStyle.$bitstoneRed;
        padding: 0;
        font-size: 1rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 0 5px 5px 0;
        outline: none;
        box-shadow: none;

        @include hoverAndFocus;

        .p-datepicker table td>span.p-highlight {
            color: generalStyle.$bitstoneRed;
            background: #EEF2FF;
        }
    }
}

@media only screen and (max-width: 958px) {
    .userDialog.p-dialog {
        width: 370px;

        @include modalCommonStyles;

        .p-dialog-footer {
            height: 45px;

            button {
                font-size: 14px;
                height: 30px;
            }
        }

        label {
            font-size: 14px;
            color: generalStyle.$labelTextColor;
            font-family: generalStyle.$fontFamily;
        }

        .field,
        .p-calendar {
            .p-inputtext {
                height: 30px;
                font-size: 14px;
            }
        }

        .double-row {
            .field {
                .p-dropdown-label {
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                }

                .p-inputtext,
                .p-multiselect {
                    height: 30px;
                    .p-multiselect-label {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .p-multiselect-panel {
        .p-multiselect-items .p-multiselect-item {
            display: flex;
            align-items: center;
            padding: 3px 10px !important;

            .p-checkbox {
                margin-right: 0.2rem !important;
                display: flex;
                align-items: center;
                .p-checkbox-box {
                    width: 15px;
                    height: 15px;

                    .p-checkbox-icon {
                        font-size: 12px;
                    }
                }
            }
            >span {
                font-size: 12px;
            }
        }

        .p-multiselect-items-wrapper {
            @include verticalScrollbar;
        }
    }

    .p-dropdown-panel {
        .p-dropdown-items .p-dropdown-item {
            padding: 7px 12px;
        }

        .p-dropdown-items-wrapper {
            @include verticalScrollbar;
        }
    }
}