@use 'src/assets/styles/variables' as generalStyle;
@import 'src/assets/styles/_data-table-styles';
@import 'src/assets/styles/_modal-styles';
@import 'src/assets/styles/_general-styles';


.userExtraDialog.p-dialog {
    width: 511px;
    .p-calendar {
        width: 100%;

        .p-inputtext {
            color: generalStyle.$textColor;
            font-size: generalStyle.$textFontSize;
            font-family: generalStyle.$fontFamily;
            border: none;
            box-shadow: none;

            @include hoverAndFocusExternalComponents;
        }

    }

    .p-calendar-w-btn-right .p-datepicker-trigger {
        color: #fff;
        background: generalStyle.$bitstoneRed;
        border: 1px solid generalStyle.$bitstoneRed;
        padding: 0;
        font-size: 1rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 0 5px 5px 0;
        outline: none;
        box-shadow: none;

        @include hoverAndFocus;

        .p-datepicker table td>span.p-highlight {
            color: generalStyle.$bitstoneRed;
            background: #EEF2FF;
        }
    }

    &::-webkit-scrollbar {
        width: 2px;
        background-color: #e6e6e6;
        border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background: generalStyle.$bitstoneRed;
        border-radius: 12px;
    }

    .pi.pi-times {
        font-size: 20px;
    }

    .p-dialog-header-icons {
        align-self: auto;
    }

    .p-dialog-content {
        padding: 0;
        background-color: generalStyle.$backgroundColor;
        padding-top: 1rem;
    }

    .p-dialog-header {
        box-shadow: 0px 3px 20px generalStyle.$shadowColor;
        z-index: 500;
        border-radius: 6px 6px 0px 0px;
        height: 54px;

        .p-dialog-header-icon {
            box-shadow: 0 0 0 0 transparent !important;
            outline: none !important;
            transition: .5s ease-in-out !important;

            &:hover {
                background-color: #fff !important;
                color: generalStyle.$bitstoneRed !important;
                rotate: 3.1416rad;
                box-shadow: 0 0 0 0 transparent;
                outline: none;
            }
        }
    }

    .p-accordion .p-accordion-header .p-accordion-header-link {
        border: none;
        height: 25px;

        @media only screen and (max-width: 425px) {
            >span {
                font-size: 12px;
            }
        }
    }

    .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        height: 25px;
    }

    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
        background: #e7e9eb;
        border-radius: 0px;
        height: 25px;
    }

    .p-accordion .p-accordion-content {
        border: none;
        background: generalStyle.$backgroundColor;
        padding: 2rem;
        margin-top: -1.5rem;

        @media only screen and (max-width: 425px) {
            padding: 1.5rem;
        }
    }

    .modal-bitstone-info {
        display: flex;
        flex-direction: column;

        label {
            color: generalStyle.$labelTextColor;
            font-size: generalStyle.$labelFontSize;
            font-family: generalStyle.$fontFamily;
            margin-left: 5px;
            min-width: 53px;
            margin: 15px 0 5px 0;
        }

        .p-inputtextarea {
            height: auto;
            resize: vertical;
            -ms-overflow-style: none;
            scrollbar-width: none;
            overflow-y: auto;
            overflow-x: hidden;
            border: none;

            &::-webkit-scrollbar {
                width: 2px;
                background-color: #e6e6e6;
                border-radius: 12px;
            }

            &::-webkit-scrollbar-thumb {
                background: generalStyle.$bitstoneRed;
                border-radius: 12px;
            }

            &:enabled:hover {
                border: 1px solid generalStyle.$bitstoneRed;
            }

            &:enabled:focus {
                box-shadow: 0 0 0 0 transparent;
                outline: none;
                border: 1px solid generalStyle.$bitstoneRed;
            }
        }

        .double-row {
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            .p-calendar {
                width: 100%;

                .p-inputtext {
                    color: generalStyle.$textColor;
                    font-size: generalStyle.$textFontSize;
                    font-family: generalStyle.$fontFamily;
                    border: none;

                    &:enabled:hover {
                        cursor: pointer;
                    }

                    &:enabled:focus {
                        box-shadow: 0 0 0 0 transparent;
                        outline: none;
                        border: none;
                    }
                }
            }

            .p-calendar-w-btn-right .p-datepicker-trigger {
                color: #fff;
                background: generalStyle.$bitstoneRed;
                border: 1px solid generalStyle.$bitstoneRed;
                padding: 0;
                font-size: 1rem;
                transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
                border-radius: 0 5px 5px 0;
                outline: none;

                &:hover {
                    background: generalStyle.$bitstoneRed;
                    border: 1px solid generalStyle.$bitstoneRed;
                }
            }
        }

        .p-multiselect {
            border-radius: 6px;
            border-color: #fff;
            height: 40px;

            .p-multiselect-label {
                color: generalStyle.$textColor;
                font-size: generalStyle.$textFontSize;
                font-family: generalStyle.$fontFamily;
            }

            .p-multiselect-label-container {
                align-items: center;
                display: flex;
            }

            &:not(.p-disabled).p-focus {
                box-shadow: 0 0 0 0 transparent;
                border: 1px solid generalStyle.$bitstoneRed;
            }

            &:not(.p-disabled):hover {
                box-shadow: 0 0 0 0 transparent;
                border: 1px solid generalStyle.$bitstoneRed;
            }
        }

        .triple-row {
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            .column {
                display: flex;
                flex-direction: column;
                width: 48%;

                label {
                    color: generalStyle.$labelTextColor;
                    font-size: generalStyle.$labelFontSize;
                    font-family: generalStyle.$fontFamily;
                    margin: 15px 0 5px 0;
                }

                .p-inputtext {
                    border: 1px solid #fff;
                    height: 40px;
                    font-size: generalStyle.$textFontSize;
                    color: generalStyle.$textColor;
                    font-family: generalStyle.$fontFamily;

                    &:enabled:hover {
                        border: 1px solid generalStyle.$bitstoneRed;
                    }

                    &:enabled:focus {
                        box-shadow: 0 0 0 0 transparent;
                        outline: none;
                        border: 1px solid generalStyle.$bitstoneRed;
                    }
                }

            }

        }
    }

    .modal-relevant-experience-info {
        display: flex;
        flex-direction: column;

        label {
            color: generalStyle.$labelTextColor;
            font-size: generalStyle.$labelFontSize;
            font-family: generalStyle.$fontFamily;
            min-width: 150px;
            margin: 15px 0 5px 0;
        }

        .double-row {
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            .flex-1 {
                display: flex;
                flex-direction: column;
            }

            .p-dropdown {
                width: 97%;
                border-radius: 6px;
                border-color: #fff;
                height: 40px;

                .p-dropdown-label {
                    color: generalStyle.$textColor;
                }

                .p-placeholder {
                    color: generalStyle.$labelTextColor;
                }

                &:not(.p-disabled).p-focus {
                    box-shadow: 0 0 0 0 transparent;
                    border-color: transparent;
                    border: 1px solid generalStyle.$bitstoneRed;
                }

                &:not(.p-disabled):hover {
                    box-shadow: 0 0 0 0 transparent;
                    border: 1px solid generalStyle.$bitstoneRed;
                }
            }
        }

        .p-inputtext {
            border: 1px solid #fff;
            height: 40px;
            font-size: generalStyle.$textFontSize;
            color: generalStyle.$textColor;
            font-family: generalStyle.$fontFamily;

            &:enabled:hover {
                border: 1px solid generalStyle.$bitstoneRed;
            }

            &:enabled:focus {
                box-shadow: 0 0 0 0 transparent;
                outline: none;
                border: 1px solid generalStyle.$bitstoneRed;
            }
        }

        .p-inputtextarea {
            height: auto;
        }

        .delete-exp {
            text-align: end;
            color: generalStyle.$labelTextColor;
            font-size: generalStyle.$textFontSize;
            font-family: generalStyle.$fontFamily;

            &:hover {
                text-decoration: underline;
                font-size: generalStyle.$textFontSize;
                font-family: generalStyle.$fontFamily;
                cursor: pointer;
            }
        }

        .line {
            border-bottom: 1px solid generalStyle.$lineColor;
            width: 100%;
            margin: auto;
        }
    }

    .button {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .project-container {
        .row {
            display: flex;
            flex-direction: column;
            margin: 5px;

            label {
                color: generalStyle.$labelTextColor;
                font-size: generalStyle.$labelFontSize;
                font-family: generalStyle.$fontFamily;
                min-width: 150px;
                margin: 15px 0 5px 0;
            }

            .p-inputtext {
                border: 1px solid #fff;
                height: 40px;
                font-size: generalStyle.$textFontSize;
                color: generalStyle.$textColor;
                font-family: generalStyle.$fontFamily;

                &:enabled:hover {
                    border: 1px solid generalStyle.$bitstoneRed;
                }

                &:enabled:focus {
                    box-shadow: 0 0 0 0 transparent;
                    outline: none;
                    border: 1px solid generalStyle.$bitstoneRed;
                }
            }

            .p-dropdown {
                @include displayFlexRowStyles;
                border-color: #fff;
                border: none;
    
                .p-dropdown-label {
                    color: generalStyle.$textColor;
                    padding: 10px 10px;
                    border: none !important;
                }
    
                @include hoverAndFocusExternalComponents;
                .pi-chevron-down:before {
                    color: generalStyle.$textColor;
                }
            }

            .p-inputtextarea {
                min-height: 80px;

                &:enabled:hover {
                    border: 1px solid generalStyle.$bitstoneRed;
                }

                &:enabled:focus {
                    box-shadow: 0 0 0 0 transparent;
                    outline: none;
                    border: 1px solid generalStyle.$bitstoneRed;
                }

                &::-webkit-scrollbar {
                    width: 2px;
                    background-color: #e6e6e6;
                    border-radius: 12px;
                }

                &::-webkit-scrollbar-thumb {
                    background: generalStyle.$bitstoneRed;
                    border-radius: 12px;
                }
            }
        }

        .delete-exp {
            text-align: end;
            color: generalStyle.$labelTextColor;
            font-size: generalStyle.$textFontSize;
            font-family: generalStyle.$fontFamily;

            &:hover {
                text-decoration: underline;
                font-size: generalStyle.$textFontSize;
                font-family: generalStyle.$fontFamily;
                cursor: pointer;
            }
        }

        .line {
            border-bottom: 1px solid generalStyle.$lineColor;
            width: 100%;
            margin: auto;
        }
    }

    .modal-personal-info {
        display: flex;
        flex-direction: column;

        label {
            color: generalStyle.$labelTextColor;
            font-size: generalStyle.$labelFontSize;
            font-family: generalStyle.$fontFamily;
            margin: 15px 0 5px 0;
        }

        .dietary {
            border-radius: 6px;
            border: 1px solid #fff;
            font-size: generalStyle.$textFontSize;
            color: generalStyle.$textColor;
            font-family: generalStyle.$fontFamily;
            resize: vertical;
            padding: 10px;
            min-height: 100px;
            &:hover {
                border: 1px solid generalStyle.$bitstoneRed;
            }

            &:focus {
                box-shadow: 0 0 0 0 transparent;
                outline: none;
                border: 1px solid generalStyle.$bitstoneRed;
            }
        }

        .p-inputtext {
            border: 1px solid #fff;
            height: 40px;
            font-size: generalStyle.$textFontSize;
            color: generalStyle.$textColor;
            font-family: generalStyle.$fontFamily;

            &:enabled:hover {
                border: 1px solid generalStyle.$bitstoneRed;
            }

            &:enabled:focus {
                box-shadow: 0 0 0 0 transparent;
                outline: none;
                border: 1px solid generalStyle.$bitstoneRed;
            }
        }

        .skills {
            position: relative;

            .skill-button {
                display: flex;
                justify-content: center;
                position: absolute;
                width: fit-content;
                top: 23px;
                right: -3px;
            }

            .key {
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
                width: 50px;
                align-items: center;

                .exclamation-icon {
                    position: relative;
                    display: inline-block;
                    height: 33px;
                    margin-left: 10px;
                    cursor: pointer;

                    .p-button {
                        background-color: transparent;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        color: #000;
                        margin-left: -17px;
                    }
                }

                .exclamation-icon .tooltip {
                    visibility: hidden;
                    width: 250px;
                    background-color: rgb(210, 210, 210);
                    color: #3d3d3d;
                    text-align: center;
                    border-radius: 6px;
                    padding: 5px 0;
                    position: absolute;
                    z-index: 1;
                    top: -5px;
                    left: 110%;
                    padding: 3px;
                }

                .exclamation-icon:hover .tooltip {
                    visibility: visible;
                }
            }
        }

        .p-chips-multiple-container {
            height: 44px;

            &:not(.p-disabled):hover {
                border: 1px solid generalStyle.$bitstoneRed;
            }

            &:not(.p-disabled).p-focus {
                box-shadow: 0 0 0 0 transparent;
                outline: none;
                border: 1px solid generalStyle.$bitstoneRed;
            }

            .p-chips-input-token {
                input {
                    font-size: 14px;
                }
            }

            .p-chips-token {
                color: generalStyle.$bitstoneRed;
                padding: 0.375rem 0.75rem;
                margin-right: 0.5rem;
                background: #f7f8f9;
                border-radius: 6px;

                &-label {
                    color: generalStyle.$textColor;
                    font-size: 13px;
                }
                &-icon {
                    color: generalStyle.$bitstoneRed;
                    
                }
            }
        }

        .education-input {
            display: flex;
            flex-direction: row;
            position: relative;

            &-text.p-inputtext {
                margin: 5px 0;
            }

            .delete-education {
                background: generalStyle.$backgroundColor;
                position: absolute;
                top: 11px;
                left: 94%;
                &:hover {
                    background: none;
                }
            }
        }

        .p-multiselect.p-multiselect-chip .p-multiselect-token {
            padding: 0.375rem 0.75rem;
            margin-right: 0.5rem;
            background: #f7f8f9;
            border-radius: 6px;

            &-label {
                color: generalStyle.$textColor;
            }
            &-icon {
                color: generalStyle.$bitstoneRed;

            }
        }

        .p-multiselect {
            border-radius: 6px;
            border-color: #fff;
            height: 40px;
            position: relative;
            width: 90%;

            .p-multiselect-label {
                color: generalStyle.$textColor;
                font-size: generalStyle.$textFontSize;
                font-family: generalStyle.$fontFamily;
            }

            .p-multiselect-label-container {
                align-items: center;
                display: flex;
            }

            &:not(.p-disabled).p-focus {
                box-shadow: 0 0 0 0 transparent;
                border: 1px solid generalStyle.$bitstoneRed;
            }

            &:not(.p-disabled):hover {
                box-shadow: 0 0 0 0 transparent;
                border: 1px solid generalStyle.$bitstoneRed;
            }

            .pi-chevron-down:before {
                color: generalStyle.$textColor;
            }
        }

        .vegan-radio {
            display: flex;
            flex-direction: row;

            .radio {
                margin-right: 3rem;
                display: flex;
                flex-direction: row;
                width: 60px;
                justify-content: space-around;
                align-items: center;

                label {
                    color: #7f8692;
                    font-size: 14px;
                    font-family: "Futura Book", sans-serif;
                    margin: 0;
                }

                .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
                    background-color:  generalStyle.$textColor;
                }

                .p-radiobutton .p-radiobutton-box.p-highlight {
                    border-color:  generalStyle.$textColor;
                    background:  generalStyle.$textColor;
                }

                .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
                    outline: none;
                    outline-offset: 0;
                    box-shadow: none;
                    border-color: generalStyle.$textColor;
                }

                .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
                    border-color: generalStyle.$textColor;
                }
            }
        }
    }

    .p-dialog-footer {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 1rem 1rem;
        box-shadow: 0px -1px 6px generalStyle.$shadowColor;
        z-index: 500;
    }
}

.warningDialog.p-dialog {
    .p-dialog-header {
        height: 54px;

        .p-dialog-header-icon {
            box-shadow: 0 0 0 0 transparent !important;
            outline: none !important;
            transition: .5s ease-in-out !important;
            position: absolute;
            right: 10px;
            top: 11px;

            &:hover {
                background-color: #fff !important;
                color: generalStyle.$bitstoneRed !important;
                rotate: 3.1416rad;
                box-shadow: 0 0 0 0 transparent;
                outline: none;
            }
        }
    }
}

.skillsDialog.p-dialog {
    width: 400px;

    .p-dialog-content {
        padding: 1rem 1.5rem 2rem 1.5rem;
        background-color: #f7f8f9
    }

    .p-dialog-header {
        box-shadow: 0px 6px 6px generalStyle.$shadowColor;
        z-index: 500;
        border-radius: 6px 6px 0px 0px;
        height: 54px;

        .p-dialog-title {
            font-weight: 700;
            font-size: 20px;
            color: generalStyle.$textColor;
            font-family: generalStyle.$fontFamily
        }

        .p-dialog-header-icon {
            box-shadow: 0 0 0 0 transparent !important;
            outline: none !important;
            transition: .5s ease-in-out !important;
            position: absolute;
            right: 10px;
            top: 11px;

            &:hover {
                background-color: #fff !important;
                color: generalStyle.$bitstoneRed !important;
                rotate: 3.1416rad;
                box-shadow: 0 0 0 0 transparent;
                outline: none;
            }
        }
    }

    .p-chips-multiple-container {
        border: none;

        &:not(.p-disabled):hover {
            border: 1px solid generalStyle.$bitstoneRed;
        }

        &:not(.p-disabled).p-focus {
            box-shadow: 0 0 0 0 transparent;
            outline: none;
            border: 1px solid generalStyle.$bitstoneRed;
        }

        .p-chips-input-token {
            input {
                font-size: 14px;
            }
        }

        .p-chips-token {
            color: generalStyle.$bitstoneRed;
            padding: 0.375rem 0.75rem;
            margin-right: 0.5rem;
            background: #f7f8f9;
            border-radius: 6px;

            &-label {
                font-size: 13px;
            }

            button {
                background-color: transparent;
                border-color: transparent;
                color: #ec1c24;
                border: none;

            }
        }
    }

    .p-dialog-footer {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 1rem 1rem;
        box-shadow: 0px -1px 6px rgba(127, 134, 146, 0.0509803922);
    }
}

@media only screen and (max-width: 425px) {
    .userExtraDialog.p-dialog {
        width: 370px !important;

        .pi.pi-times {
            font-size: 15px;
        }

        .p-dialog-header {
            box-shadow: 0px 6px 6px generalStyle.$shadowColor;
            z-index: 500;
            border-radius: 6px 6px 0px 0px;
            height: 40px;

            .p-dialog-title {
                font-weight: 700;
                font-size: 18px;
                color: generalStyle.$textColor;
                font-family: generalStyle.$fontFamily
            }

            .p-dialog-header-icon {
                box-shadow: 0 0 0 0 transparent !important;
                outline: none !important;
                transition: .5s ease-in-out !important;

                &:hover {
                    background-color: #fff !important;
                    color: generalStyle.$bitstoneRed !important;
                    rotate: 3.1416rad;
                    box-shadow: 0 0 0 0 transparent;
                    outline: none;
                }
            }
        }

        .p-dialog-content {
            padding: 0;
            background-color: generalStyle.$backgroundColor;
            padding-top: 1rem;
        }

        .p-dialog-footer {
            height: 45px;

            button {
                font-size: 14px;
                height: 30px;
            }
        }

        label {
            font-size: 14px;
            color: generalStyle.$labelTextColor;
            font-family: generalStyle.$fontFamily
        }

        .modal-bitstone-info {
            label {
                font-size: 14px;
            }

            .p-inputtextarea {
                font-size: 14px;
            }

            .double-row {
                display: flex;
                justify-content: space-between;
                flex-direction: row;

                .p-calendar {
                    width: 100%;

                    .p-inputtext {
                        font-size: 14px;
                        height: 30px;
                    }
                }
            }

            .p-multiselect {
                height: 30px;

                .p-multiselect-label {
                    font-size: 14px;
                }
            }

            .triple-row {
                flex-direction: column;

                .column {
                    label {
                        font-size: 14px;
                    }

                    .p-inputtext {
                        height: 30px;
                        font-size: 14px;
                        width: 100%;
                    }
                }
            }
        }

        .modal-relevant-experience-info {
            label {
                font-size: 14px;
            }

            .double-row {
                .p-dropdown {
                    height: 30px;

                    .p-dropdown-label {
                        font-size: 14px;
                        border: transparent;
                        display: flex;
                        align-items: center;
                    }

                    .p-placeholder {
                        font-size: 14px;
                        border: transparent;
                    }

                }
            }

            .p-inputtext {
                height: 30px;
                font-size: 14px;
            }

            .p-inputtextarea {
                height: auto;
                resize: vertical;
            }

            .delete-exp {
                font-size: 12px;
            }
        }

        .button button {
            background-color: transparent;
            border: transparent;
            top: -20px;
        }

        .project-container {
            .row {
                label {
                    font-size: 14px;
                }

                .p-inputtext {
                    height: 30px;
                    font-size: 14px;
                }

                .p-inputtextarea {
                    resize: vertical;
                    min-height: 65px;
                }
            }

            .delete-exp {
                font-size: 12px;
            }
        }

        .modal-personal-info {
            label {
                font-size: 14px;
            }
    
            .p-inputtext {
                height: 30px;
                font-size: 14px;
            }
    
            .skills {
                .skill-button {
                    top: 19px;
                    left: 96%;
                    width: 20px;
                    height: 20px;

                    button {
                        background-color: transparent;
                        border: transparent;
                    }
                }
            }
    
            .p-chips-multiple-container {
                height: 35px;
                display: flex;
                align-items: center;
                padding: 0 0 0 5px;
    
                .p-chips-input-token {
                    input {
                        font-size: 11px;
                    }
                }
    
                .p-chips-token {
                    &-label, span {
                        font-size: 11px;
                    }
                }
            }
    
            .education-input {
                .delete-education {
                    top: 5px;
                    left: 91%;
                    background-color: transparent;
                    border: transparent;
                }
            }
    
            .p-multiselect.p-multiselect-chip .p-multiselect-token {
                span {
                    font-size: 11px;
                }
            }
    
            .p-multiselect {
                height: 35px;
            }
    
            .vegan-radio {
                .radio {
                    margin-right: 1rem;
                }
            }
        }
    }

    .skillsDialog.p-dialog {
        width: 250px;

        .pi.pi-times {
            font-size: 13px;
        }
    
        .p-dialog-content {
            padding: 1rem 1.5rem;
            background-color: #f7f8f9
        }
    
        .p-dialog-header {
            height: 44px;
    
            .p-dialog-title {
                font-size: 16px;
            }
    
            .p-dialog-header-icon {
                top: 7px;
            }
        }
    
        .p-chips-multiple-container {
            border: none;

            .p-chips-input-token {
                padding: 0;
                input {
                    font-size: 11px;
                }
            }
    
            .p-chips-token {
                &-label {
                    font-size: 11px;
                }
    
                .p-chips-token-icon {
                    font-size: 11px;
                }
            }
        }
    
        .p-dialog-footer {
            height: 40px;
            button {
                background-color: transparent;
                border: transparent;
                color:  generalStyle.$bitstoneRed;
                font-size: 14px;
            }
        }
    }
}