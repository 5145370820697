@use 'src/assets/styles/variables' as generalStyle;

@mixin displayFlexRowStyles {
    align-items: center;
    display: flex;
    flex-direction: row;
}

@mixin textStyle {
    font-family: generalStyle.$fontFamily;
    font-size: generalStyle.$textFontSize;
    color: generalStyle.$textColor;
}