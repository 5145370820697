$primary-gray-color: rgb(77, 77, 77);
@use 'src/assets/styles/variables' as generalStyle;

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80px;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0px 3px 20px #00000008;

  @media only screen and (max-width: 768px) {
    height: 60px;
  }

  .header-component {
    position: relative;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    margin: 0 auto;
    padding: 0 10px 0 20px;


    @media only screen and (min-width: 768px) {
      .menu-logo {
        display: none;
      }

      .side-menu {
        display: block;
      }
    }

    @media only screen and (max-width: 768px) {
      height: 60px;
    }

    .logo {
      width: 92px;
      margin: 0;

      &:hover {
        cursor: pointer;
      }
    }

    .menu-logo {
      display: none;

      @media only screen and (max-width: 425px) {
        width: 65px;
      }
    }

    .header-flex-2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 545px;

      @media only screen and (max-width: 425px) {
        justify-content: flex-end;
        .user-info {
          display: none;
        }
      }

      @media only screen and (min-width: 425px) and (max-width: 768px) {
        width: 390px;
        justify-content: flex-end;

        .user-info {
          display: block;
        }
      }

      @media only screen and (min-width: 768px) and (max-width: 1000px) {
        justify-content: space-between;
      }
    }

    .search-bar .p-inputtext {
      background-color: #fff;
      color: generalStyle.$labelTextColor;
      border: 1px solid #d1d5db;
      border-radius: 23px;
      width: 309px;
      height: 40px;
      font-family: generalStyle.$fontFamily;
      font-size: 17px;

      &:enabled:hover,
      &:enabled:focus {
        border: 1px solid generalStyle.$bitstoneRed;
        box-shadow: 0 0 0 0 transparent;
        outline: none;
      }

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 22px;
        width: 22px;
        background: url('../../assets/icons/cancel-2.png') no-repeat 50% 50%;
        background-size: contain;
      }

      @media only screen and (max-width: 425px) {
        width: 128px;
        height: 33px;
        font-size: 14px;

        .p-input-icon-left>i:first-of-type {
          font-size: 14px;
          margin-top: -0.5rem;
        }
      }

      @media only screen and (min-width: 425px) and (max-width: 768px) {
        width: 230px;
        height: 33px;
      }
    }

    .header-user-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 768px) {
        .user-info {
          display: none;
        }
      }
    }

    .my-account,
    .my-team {
      display: flex;
      padding: 5px;
      align-items: center;
      justify-content: center;

      svg {
        transition: transform 0.2s;
        color: $primary-gray-color;
        font-size: 18px;
      }

      &:hover {
        border-color: #000;
        cursor: pointer;

        svg {
          color: #000;
          transform: scale(1.25);
        }
      }
    }

    .my-account {
      margin-left: 10px;

      img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        object-fit: cover;
        object-position: 0% 20%;

        @media only screen and (min-width: 425px) and (max-width: 768px) {
          height: 40px;
          width: 40px;
        }

        @media only screen and (max-width: 425px) {
          height: 40px;
          width: 40px;
        }
      }
    }

    .my-team {
      margin-left: 20px;
    }

    .user-info {
      p {
        margin: 10px;
        color: generalStyle.$textColor;
        font-family: generalStyle.$fontFamily;
        font-size: generalStyle.$textFontSize;
      }

      &__name:hover {
        color: #000;
        cursor: default;
      }
    }

    .header-info-popup {
      margin-bottom: 20px;
      margin-left: 5px;
      transition: transform 0.2s;
      color: $primary-gray-color;

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .header-component {
      padding: 10px;

      .header-logo {
        display: none;
      }

      .menu-logo {
        display: block;
        margin-left: 10px;

        .logo>svg {
          width: 25px;
        }
      }

      .side-menu {
        position: fixed;
        background-color: #fff;
        padding: 7px 14px;
        border-radius: 6px;
        top: 39px;

        &-section {
          font-size: 18px;
          font-family: generalStyle.$fontFamily;

          .sideMenu-option {
            margin: 10px 0;
            border-radius: 6px;
            display: flex;
            align-content: center;
            height: 35px;
            flex-wrap: wrap;
            font-size: 16px;
            svg {
              margin: 0 6px;
            }

            &:hover svg > path {
                fill: generalStyle.$bitstoneRed;
            }

            svg>path {
              fill: #000;
            }

            &.active {
              color: generalStyle.$bitstoneRed;
              background-color: #eee;

              svg>path {
                fill: generalStyle.$bitstoneRed;
              }
            }
            a { 
              text-decoration: none;
            }

            .obj-btn {
              color: generalStyle.$textColor;
              font-family: generalStyle.$fontFamily;
              padding: 0;
              font-size: 16px;
              text-decoration: none;
              span {
                margin-right: 0;
                margin-left: 0;
                svg {
                  margin: 0 4px;
                  width: 20px;
                  height: 20px;
                  >path {
                    fill: #000;
                  }
                }
              }
             
            }
          }
        }
      }

      .my-account:hover,
      .my-team:hover svg {
        transform: scale(1.1);
      }

      .my-account,
      .my-team {
        margin-left: 5px;
      }

      .header-info-popup {
        margin-left: 5px;
      }
    }
  }
}