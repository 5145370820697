@use 'src/assets/styles/variables' as generalStyle;
@import 'src/assets/styles/_general-styles';

.employee {
  &-card {
    padding: 1em 1em 2em;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 0px 8px #7F869233;

    &:hover {
      box-shadow: 0px 8px 16px #7F86924D;
      cursor: pointer;
    }

    .user-info {
      .name {
        font-weight: bold;
        font-size: 22px;
        color: generalStyle.$bitstoneRed;
        margin: 15px 0 0 0;
        letter-spacing: 0;
      }

      .job {
        font-weight: bold;
        @include textStyle;
      }

      .since {
        @include textStyle;
        font-weight: normal;
        margin: -5px 0 0 0;
      }
    }

    &-teamLeader {
      width: 34px;
      height: 36px;
      background-color: generalStyle.$bitstoneRed;
      z-index: 50;
      right: 20px;
      top: 0px;
      padding-top: 6px;
      border-radius: 0 0 2px 2px;

      svg path {
        fill: #fff;
      }
    }
  }

  &-image {
    .image-loader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%; 
      height: 100%; 
      background-color: rgba(255, 255, 255, 0.8);
      font-weight: bold;
  }
  
    img {
      max-height: 210px;
      object-fit: cover;
      border-radius: 6px;
      object-position: 0% 20%;
    }
  }
}

@media only screen and (max-width: 576px) {
  .employee-card {
    gap: 1em;
    padding: 1em;
    .employee-image {
      flex-basis: 50%;
      img {
        max-height: 180px;
      }

    }
  }
}


