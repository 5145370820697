.not-found-page {
  min-height : calc(100vh - 80px);
  text-align : center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-weight : 600;
    font-size : 100px;
    color : #666666;
    font-size : 60px;
  }
}
