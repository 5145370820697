.statistics-charts {
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 10px;
    &__byCriterion {
        padding: 16px;
        flex: 1 1 auto;
        display: flex;
        margin: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        overflow-x: auto;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.36) 0px 1px 4px;
        &__table {
            width: 100%;
        }
        &__chart {
            width: 100%;
            .recharts-wrapper,
            .recharts-responsive-container {
                margin: auto;
            }
        }
    }
    .recharts-surface {
        height: 92%;
	}
    .recharts-legend-wrapper {
        left: 110px !important;
    }
}
