@use 'src/assets/styles/variables' as generalStyle;
@import 'src/assets/styles/_general-styles';

.cardLink-container {
    height: 130px;
    background-color: #fff;
    box-shadow: 0px 0px 4px #7F869240;
    transition: transform 0.3s ease-in-out;
    border-radius: 6px;

    @media only screen and (max-width: 425px) { 
        margin-right: 0;
        justify-content: space-evenly;
    }

    .btn-arrow.p-button {
        position: absolute;
        background: none;
        color: #000;
        border: none;
        top: 0;
        left: auto;
        right: 0;
        outline: none;

        &:hover, &:focus, &:active {
            background: none;
            border: none;
            outline: none;
            box-shadow: none;
        }
    }

    .cardLink-icon {
        border-radius: 50%;
        background-color: generalStyle.$backgroundColor;
        width: 50px;
        height: 50px;
        margin-top: 20px;

        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: scale-down;
        } 
        span {
            font-weight: 600;
        }
    }

    .link-name {
        p {
            font-size: 20px;
            width: max-content;
            margin-top: 15px;
        }

        .span {
            .btn-arrow-down.p-button {
                background: none;
                color: #000;
                border: none;
                outline: none;
                margin-left: 6px;
                box-shadow: none;
                width: 20px;
            }
        }
    }

    &:hover {
        cursor: pointer;
        transform: translateY(-5px);
        transition-delay: 0.2s;
        box-shadow: 0px 0px 30px #7F869259;
        z-index: 101;
        border-radius: 6px 6px 6px 6px;
        background: #FFFFFF 0% 0% no-repeat padding-box;

        .btn-arrow.p-button span{
            color: generalStyle.$bitstoneRed;
            font-weight: 600;
        }

        .link-name {
            p {
                font-weight: 600;
            }
            .span {
                .btn-arrow-down.p-button {
                    background: none;
                    border: none;
                    outline: none;
                    padding: 4px 0 0 0;

                    span {
                        transform: scale(1, -1);
                        transition: transform 0.3s ease-in-out;
                    }
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }

    .description {
        position: absolute;
        z-index: 100;
        top: 125px;
        bottom: -20px;
        left: 0;
        width: 100%;
        background-color: #fff;
        height: fit-content;
        padding: 5px 14px;
        border-top: 0.5px solid generalStyle.$lineColor;
        box-shadow: 0px 4px 8px #7F869259;
        border-radius: 0 0 6px 6px;
        color: generalStyle.$labelTextColor;
        font-size: 16px;
        pointer-events: auto;
    }

    @media only screen and (max-width: 425px) {
        .btn-arrow.p-button {
            top: -5px;
            span {
                font-size: 14px;
            }
        }

        .link-name p {
            font-size: 16px;
        }

        .description {
            bottom: -20px;
            left: 0;

            span {
                font-size: 12px;
            }
        }
    }

    @media only screen and (min-width: 425px) and (max-width: 768px) {
        .btn-arrow.p-button {
            top: -4px;
            right: 0;
            left: auto;

            span {
                font-size: 14px;
            }
        }

        .link-name p {
            font-size: 18px;
        }

        .description {
            bottom: -20px;
            left: 0;
            span {
                font-size: 12px;
            }
        }
    }

    @media only screen and (min-width: 769px) and (max-width: 1024px) {
        .description {
            bottom: -20px;
            left: 0;
            font-size: 14px;
        }
    }

}