@import url('https://cdn.syncfusion.com/ej2/material.css');

.gantt-chart {
    padding: 20px;
    max-width: 1400px;
    margin: 0px auto 60px;
    td>div {
        text-overflow: clip !important;
    }
}

#timeline-name-column {
    display: flex;
    align-items: center;
    &__icon {
        padding-left: 4px;
    }
}