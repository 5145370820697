@use 'src/assets/styles/variables' as generalStyle;

.filter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  .clear-filters {
    font-size: generalStyle.$textFontSize;
    font-family: generalStyle.$fontFamily;
    color: generalStyle.$labelTextColor;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-right: 0.5rem;

    .icon, .text {
      transition: opacity 0.3s ease;
    }

    .icon {
      margin-right: 5px;
      opacity: 1;
    }

    .text {
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }

    &:hover {
      text-decoration: underline;

      .text {
        opacity: 1;
      }

      .icon {
        opacity: 0;
      }
    }
  }

  .dropdown-component .p-multiselect,
  .dropdown-component .p-dropdown {
    width: 170px;
    border-color: #fff;
    height: 40px;
    margin-right: 16px;

    &:hover {
      border-color: generalStyle.$bitstoneRed;
    }

    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-color: generalStyle.$bitstoneRed;
    }

    &:not(.p-disabled):hover {
      border-color: generalStyle.$bitstoneRed;
    }

    .p-multiselect-label,
    .p-dropdown-label {
      color: generalStyle.$labelTextColor;
      font-family: generalStyle.$fontFamily;
      font-size: generalStyle.$textFontSize;
      padding-left: 11px;
      align-self: center;

      &.p-placeholder {
        color: generalStyle.$labelTextColor;
        font-family: generalStyle.$fontFamily;
        font-size: generalStyle.$textFontSize;
        padding-left: 11px;
      }
    }

    .p-multiselect-label-container {
      align-self: center;
      margin-left: 5px;
    }

    .p-multiselect-trigger {
      color: #000;
    }

    .p-dropdown-trigger {
      color: #000;
    }
  }

  .checkbox {
    .teamlead-check.p-checkbox {
      .p-checkbox-box {
        border: 1px solid #ced4da;
        box-shadow: none;
      }
      .p-checkbox-box.p-highlight {
        box-shadow: none !important;
      }
      .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        box-shadow: none !important;
      }
    }
  }

}

.p-multiselect-panel {
  .p-multiselect-header {
    display: none;
  }

  .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 3px 1.25rem !important;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;

    @media only screen and (max-width: 426px) {
      display: flex;
      align-items: flex-start;
    }

    &.p-highlight {
      color: generalStyle.$textColor !important;
      background: generalStyle.$backgroundColor !important;
    }

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none !important;
    }

    >span {
      font-family: generalStyle.$fontFamily;
      font-size: 14px;
      color: generalStyle.$textColor;

      @media only screen and (max-width: 426px) {
        margin-left: -5px;
        font-size: 12px;
      }
    }

    .p-checkbox .p-checkbox-box {
      border: 1px solid #ced4da;
      border-radius: 4px;


      @media only screen and (max-width: 426px) {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.p-dropdown-items-wrapper,
.p-multiselect-items-wrapper {
  overflow: auto;
  max-height: 210px !important;
  scrollbar-width: thin;
  scrollbar-color: generalStyle.$bitstoneRed #e6e6e6;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: none;
  }

  &::-webkit-scrollbar-thumb {
    background: generalStyle.$bitstoneRed;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-track-piece:end {
    background: none;
    margin-bottom: 10px;
  }

  &::-webkit-scrollbar-track-piece:start {
    background: none;
    margin-top: 10px;
  }
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  color: generalStyle.$labelTextColor;
  font-family: generalStyle.$fontFamily;
  font-size: generalStyle.$textFontSize;
}

.p-multiselect-panel, .p-dropdown-panel {
  margin-top: 4px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-family: generalStyle.$fontFamily;
  font-size: 14px;
  color: generalStyle.$textColor !important;

  &.p-highlight {
    color: generalStyle.$textColor !important;
    background: transparent !important;
    font-weight: 700;
  }
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #fff;
  font-size: 14px;
  border-color: generalStyle.$bitstoneRed;
  background: generalStyle.$bitstoneRed;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: generalStyle.$bitstoneRed !important;
  background: generalStyle.$bitstoneRed !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: generalStyle.$bitstoneRed !important;
  color: #fff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:focus {
  border-color: generalStyle.$bitstoneRed !important;
  color: #fff;
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: generalStyle.$bitstoneRed !important;
  background: generalStyle.$bitstoneRed !important;
  color: #fff;
}


@media only screen and (max-width: 425px) {
  .filter-container {
    gap: 10px;
    margin-top: 1rem;
    padding: 0 20px;
    width: 100vw;

    .dropdown-component .p-multiselect,
    .dropdown-component .p-dropdown {
      width: 170px;
      height: 30px;
      margin-right: 0;

      .p-multiselect-label,
      .p-dropdown-label {
        font-size: 14px;

        &.p-placeholder {
          font-size: 14px;
        }
      }
    }

    .clear-filters {
      font-size: 14px;
    }
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 12px
  }
}

@media only screen and (min-width: 425px) and (max-width: 600px) {
  .filter-container {
    gap: 12px;
    padding: 0 20px;

    .dropdown-component .p-multiselect,
    .dropdown-component .p-dropdown {
      width: 170px;
      height: 30px;
      margin-right: 0;

      .p-multiselect-label,
      .p-dropdown-label {
        font-size: 14px;

        &.p-placeholder {
          font-size: 14px;
        }
      }
    }

    .clear-filters {
      font-size: 14px;
    }
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 12px
  }
}

@media only screen and (max-width: 768px) {
  .filter-container .clear-filters:hover .text {
    font-size: 14px;
  }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
  .filter-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 20px;
    gap: 10px;

    .dropdown-component .p-multiselect,
    .dropdown-component .p-dropdown {
      width: 150px;
      height: 30px;
      margin-right: 0;

      .p-multiselect-label,
      .p-dropdown-label {
        font-size: 14px;

        &.p-placeholder {
          font-size: 14px;
        }
      }
    }

    .clear-filters {
      font-size: 14px;
    }
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 12px
  }
}

@media only screen and (min-width: 769px) and (max-width: 1150px) {
  .filter-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin-top: -5px;

    .dropdown-component .p-multiselect,
    .dropdown-component .p-dropdown {
      width: 125px;
      height: 30px;
      margin-right: 15px;

      .p-multiselect-label,
      .p-dropdown-label {
        font-size: 14px;

        &.p-placeholder {
          font-size: 14px;
        }
      }
    }

    .clear-filters {
      font-size: 14px;
    }
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 12px
  }
}

@media only screen and (min-width: 1151px) and (max-width: 1440px) {
  .filter-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin-top: -5px;

    .dropdown-component .p-multiselect,
    .dropdown-component .p-dropdown {
      width: 170px;
      height: 35px;
      margin-right: 15px;

      .p-multiselect-label,
      .p-dropdown-label {
        font-size: 14px;

        &.p-placeholder {
          font-size: 14px;
        }
      }
    }

    .clear-filters {
      font-size: 14px;
    }
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 12px
  }
}