.plans-container {
    max-width: 1400px;
    margin: 80px auto;
    padding: 20px;

    @media only screen and (max-width: 767px) {
        margin: 70px auto;
    }
    &__table {
        span {
            padding: 5px;
            margin: 0px 5px;
        }
        p {
            padding-left: 70px;
        }
    }
    &__export {
        margin: 15px 0;
        float: right;
    }
    th {
        font-weight: bold;
        &:nth-child(2) {
            min-width: 140px;
        }
    }
    th,
    td {
        padding: 8px;
        max-width: 300px;
    }
    .filter {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 0;
        margin-bottom: 15px;
        select,
        select:focus,
        select:active,
        select:selected {
            border: 0 solid transparent;
            background-color: transparent;
        }
    }
}
