.action-bar {
    width: 100%;
    margin-top: 90px;
    border-bottom: 1px solid #ddd;
    button {
        margin: 0 0 0 15px;
        padding: 5px 10px;
    }
    @media only screen and (max-width: 850px) {
        button {
            font-size: 12px;
            margin: 0 0 0 10px;
        }
    }
    @media only screen and (max-width: 767px) {
        margin-top: 80px;
        button {
            font-size: 0;
            padding: 5px 0;
            margin: 0 0 0 10px;
            min-width: 35px;
            span {
                margin: 0;
            }
        }
    }
    &__content {
        padding: 0 20px 12px 20px;
        max-width: 1400px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &__filter {
            position: relative;
            display: flex;
            &__floating {
                position: absolute;
                background-color: white;
                top: 42px;
                left: -100px;
                padding: 25px 5px;
                box-shadow: rgba(99, 99, 99, 0.4) 0px 2px 8px 0px;
                z-index: 20;
                // margin: 0 20px 0 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                align-content: center;
                flex-wrap: wrap;
                .filter {
                    flex: 1;
                    margin: 0 10px;
                    min-width: 100px;
                    select:focus,
                    select:active,
                    select:selected {
                        background-color: transparent;
                    }
                    margin: 10px 5px;
                }
            }
            &__floating-end {
                right: 0;
            }
        }
    }
}