.container-input {
    margin-bottom: 25px;
    width: 330px;
}

.container-password {
  position: relative;
    display: flex;
    flex-direction: column;
    a {
        text-align: center;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    button {
        flex: 1;
        margin-top: 15px;
    }
}

.login-error {
    color: #d8363d;
    font-weight: bold;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;

    p {
      text-align: center;
    }
}
