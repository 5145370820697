.approvals-container {
    max-width: 1400px;
    margin: 80px auto;
    padding: 20px;
    &__table {
        span {
            padding: 5px;
        }
    }
    &__export {
        margin: 15px 0;
        float: right;
    }
    tr {
        border-bottom: 1px solid #ddd;
    }
    th {
        font-weight: bold;
    }
    th,
    td {
        padding: 8px;
        word-break: break-word;
        max-width: 300px;
        min-width: 102px;
        &:last-of-type {
            min-width: 250px;   
        }
    }
    .filter {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 0;
        margin-bottom: 15px;
        select,
        select:focus,
        select:active,
        select:selected {
            border: 0 solid transparent;
            background-color: transparent;
        }
    }
    .approval-buttons {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    @media only screen and (max-width: 767px) {
        margin: 70px auto;
        td {
            word-break: unset;
            overflow: hidden;

            &:nth-of-type(2) {
                text-overflow: ellipsis;
            }

            &:last-of-type {
                min-width: 210px;   
            }
        }
        .approval-buttons {
            justify-content: space-evenly;
        }
    }
    @media only screen and (max-width: 850px) {
        td:last-of-type {
            min-width: 230px;   
        }
    }
}