.info {
    &__title {
        text-transform: capitalize;
        h3 {
            margin: 0 0 5px 0;
        }
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 0px 10px;
    }
    &__about {
        &__subtitle {
            margin-bottom: 5px;
        }
        text-align: justify;
        @media only screen and (max-width: 500px) {
            text-align: left;
        }
        p {
            margin: 5px 0;
        }
        ul {
            margin-top: 5px;
        }
    }
    &__credits {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        &__member {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            &__image {
                object-fit: cover;
                border-radius: 50%;
                height: 70px;
                width: 70px;
            }
        }
    }
}