@use 'src/assets/styles/variables' as generalStyle;

@mixin modalCommonStyles {
    .pi.pi-times {
        font-size: 20px;
    }

    .p-dialog-header-icons {
        align-self: auto;
    }

    .p-dialog-header {
        box-shadow: 0px 6px 6px generalStyle.$shadowColor;
        z-index: 500;
        border-radius: 6px 6px 0px 0px;
        height: 54px;

        .p-dialog-title {
            font-weight: 700;
            font-size: 20px;
            color: generalStyle.$textColor;
            font-family: generalStyle.$fontFamily;
        }

        .p-dialog-header-icon {
            box-shadow: 0 0 0 0 transparent !important;
            outline: none !important;
            transition: .5s ease-in-out !important;

            &:hover {
                background-color: #fff !important;
                color: generalStyle.$bitstoneRed !important;
                rotate: 3.1416rad;
                box-shadow: 0 0 0 0 transparent;
                outline: none;
            }
        }
    }

    .p-dialog-content {
        background-color: generalStyle.$backgroundColor;
        padding-top: 1rem;
    }

    .p-dialog-footer {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 1rem 1rem;
        box-shadow: 0px -1px 6px generalStyle.$shadowColor;
        z-index: 500;
         .p-button.p-button-text {
            color: generalStyle.$labelTextColor;
            background-color: transparent;
            border: none;

            &:enabled:focus,
            &:enabled:hover {
                border: none;
                outline: none;
                box-shadow: none;
                background-color: transparent;
            }
        }
    }
}


@mixin verticalScrollbar {
    &::-webkit-scrollbar {
        width: 2px;
        background-color: #e6e6e6;
        border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background: generalStyle.$bitstoneRed;
        border-radius: 12px;
    }
}

@mixin hoverAndFocus {
    &:enabled:hover {
        border: 1px solid generalStyle.$bitstoneRed;
    }

    &:enabled:focus {
        box-shadow: none;
        outline: none;
        border: 1px solid generalStyle.$bitstoneRed;
    }
}

@mixin hoverAndFocusExternalComponents {
    &:not(.p-disabled).p-focus {
        box-shadow: none;
        border-color: transparent;
        border: 1px solid generalStyle.$bitstoneRed;
    }

    &:not(.p-disabled):hover {
        box-shadow: none;
        border: 1px solid generalStyle.$bitstoneRed;
    }
}