body, h1, h2, h3, h4,
p, span, a, strong, em, b, i {
    font-family: "Futura Book", sans-serif;
    color: #262626;
}

body, p, span, a, strong, em, b, i {
    font-size: 14px;
}

@font-face {
    font-family: 'Futura Book';
    src: url('../fonts/FuturaBookFont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura Bold';
    src: url('../fonts/FuturaBoldFont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


