@use 'src/assets/styles/variables' as generalStyle;

.search {
    padding: 0 30px 0 0;
    &-bar .p-inputtext {
        border: 1px solid generalStyle.$bitstoneRed; 
        display: flex;
        align-items: flex-end;
        &-bar .p-inputtext:hover {
            border: 1px solid generalStyle.$bitstoneRed
        }
    }
    &-icon {
        margin-right: 5px;
        margin-bottom: 0.5px;
        margin-top: 0.5px;
    } 
}

.delete-icon {
    &_hidden {
        opacity: 0;
    }
    &_visible {
        opacity: 1;
        margin-bottom: 0.5 !important;
        margin-top: 0.5 !important;
        width: 20px !important;
        height: 20px !important;
    }
}

#input-with-sx {
    width: 200px;
}
