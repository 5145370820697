@use 'src/assets/styles/variables' as generalStyle;

.drawer {
  width: 400px;

  &__name {
    width: 100%;
    background-color: #f3f3f3;
    color: #4d4d4d;
    text-align: center;
    padding: 0 20px;
  }

  &__content {
    min-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;

    @media screen and (max-width: 668px) {
      min-width: 50%;
    }

    .profile-info {
      display: flex;
      align-items: center;
      gap: 15px;
      .my-account-img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
    
        img {
          height: 50px;
          width: 50px;
          border-radius: 50%;
        }
      }
      .user-info {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .p-name {
          color : generalStyle.$textColor;
          font-size: 18px;
          font-family: generalStyle.$fontFamily;
          margin: 0;  

          @media only screen and (max-width: 768px) {
             font-size: 16px;
            }
        }

        .p-email {
          color : generalStyle.$labelTextColor;
          font-size: 18px;
          font-family: generalStyle.$fontFamily;
          margin: 0;
          margin-right: 10px;

          @media only screen and (max-width: 768px) {
            font-size: 16px;
           }
        }
      }
    
    }

    & > div {
      margin: 10px;
      width: 85%;
    }
    button {
      width: 150px;
      padding: 10px 0;
      margin: 20px auto;
      color: generalStyle.$textColor;
      border: none;
      background-color: #f1f3f6;
      font-family: generalStyle.$fontFamily;
      font-size: 16px;
      text-transform: none;
      font-weight: 600;

      &:hover {
        background-color: #d1d5db;
        border: none;
      }
    }
    &__list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid rgb(241, 235, 235);
      padding: 10px;
      button {
        margin: 0;
        padding: 5px 0;
        width: 100px;
      }
      .user-name {
        padding-right: 20px;
      }
    }
    .team-name {
      display: block;
      font-size: 16px;
      text-transform: capitalize;
      font-weight: bold;
      margin: 5px 0;
      width: 95%;
      padding: 3px;
      border-bottom: 1px solid #333;
      overflow: hidden;
      max-width: 300px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &__footer {
    margin: auto 25px 25px 30px;
    button {
      display: block;
      width: 95%;
      margin: 0 auto;
      background-color: generalStyle.$bitstoneRed;
      border: none;
      color: #fff;
      box-shadow: none;
      font-family: generalStyle.$fontFamily;
      font-weight: normal;
      text-transform: none;
      height: 40px;
      font-size: 16px;
      &:hover {
        background-color: #D21219;
        border: none;
        box-shadow: none;
      }
    }
  }
}

.MuiPaper-root {
  overflow-x: hidden;
}
