@use 'src/assets/styles/variables' as generalStyle;

.admin {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .admin-container {
    max-width: 1400px;
    background-color: #f7f8f9;
    position: relative;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    width: 100vw;

    .sidemenu .container {
      margin: 7rem 2rem 0 0;
    }

    @media only screen and (max-width: 769px) {
      margin: 60px auto;
      padding: 0;
    }

    @media only screen and (min-width: 769px) and (max-width: 1440px) {
      max-width: 1400px;
      background-color: #f7f8f9;
      position: relative;
      height: 100%;
      margin: 0 auto;
      padding: 0 20px;
      display: flex;
      width: 100vw;

      .sidemenu {
        width: fit-content;

        .container {
          margin: 7rem 1rem 0 0;
          width: 57px;

          .sideMenu {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: auto;
            padding: 1rem;
            width: 70px;

            &-option {
              text-decoration: none;
              height: 46px;
              width: 235px;
            }

            button {
              width: 42px;
              height: 40px;
              font-size: 0px;
              min-width: 0;

              .css-9tj150-MuiButton-endIcon {
                margin-left: 20px;
              }
            }
          }
        }
      }
    }

    .tabview-header-admin.p-tabview {
      margin: 7rem 0 4rem 0;
    }

    .tabview-header-admin.p-tabview li .p-tabview-nav-link {
      color: generalStyle.$labelTextColor;
      font-size: 18px;
      font-family: generalStyle.$fontFamily;
      display: grid;
      justify-content: center;
      border-radius: 0;
      border: none;
      height: 55px;

      &:not(.p-disabled):focus {
        box-shadow: none !important;
      }

      .p-tabview-title {
        font-size: 18px;
        font-family: generalStyle.$fontFamily;
      }
    }

    @media only screen and (max-width: 769px) {
      .tabview-header-admin.p-tabview {
        margin: 0;
        overflow: hidden;
      }
    }

    @media only screen and (min-width: 769px) and (max-width: 1440px) {
      .tabview-header-admin.p-tabview {
        margin: 7rem 0 4rem 1rem;
        overflow: hidden;
      }
    }

    .tabview-header-admin.p-tabview .p-tabview-panels {
      padding: 0;
    }

    .tabview-header-admin.p-tabview .p-tabview-nav li .p-tabview-nav-link {
      border: transparent;
      border-width: 0 0 2px 0;
      border-color: transparent transparent #dee2e6 transparent;
      background: #7F869233;
      color: #6c757d;
      font-weight: 700;
      border-radius: 0;
      transition: box-shadow 0.2s;
      margin: 0 0 -2px 0;
      text-align: center;
      display: flex;

      i {
        margin-right: 5px;
      }

      @media only screen and (max-width: 769px) {
        text-align: center;
        display: flex;

        span {
          font-size: 14px;
          white-space: break-spaces;
        }
      }

      @media only screen and (min-width: 768px) and (max-width: 1440px) {
        text-align: center;
        display: flex;

        span {
          font-size: 16px;
          white-space: break-spaces;
        }
      }
    }

    .tabview-header-admin.p-tabview .p-tabview-nav {
      border: transparent;

      @media only screen and (max-width: 1024px) {
        width: 100%;
        overflow: inherit;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      li {
        width: 33.33%;
      }
    }

    .tabview-header-admin.p-tabview .p-tabview-nav-container .p-tabview-nav-content {
      border-radius: 6px 6px 0px 0px;
    }

    .tabview-header-admin.p-tabview .p-tabview-nav {

      li.p-highlight .p-tabview-nav-link {
        background: transparent;
        border-color: transparent;
        color: generalStyle.$bitstoneRed
      }

      .p-tabview-ink-bar {
        background-color: generalStyle.$bitstoneRed;
        display: none;
      }
    }

    .tabview-header-admin.p-tabview li .p-tabview-nav-link {
      color: generalStyle.$labelTextColor;
      font-size: 18px;
      font-family: generalStyle.$fontFamily;
      justify-content: center;
      border-radius: 0;
      border: none;
      height: 55px;
      display: flex;
      align-content: center;
      align-items: center;

      &:not(.p-disabled):focus {
        box-shadow: none !important;
      }

    }

    .tabview-header-admin.p-tabview .p-tabview-nav li .p-tabview-nav-link {
      border: transparent;
      border-width: 0 0 2px 0;
      border-color: transparent transparent #dee2e6 transparent;
      background: #7F869233;
      color: #6c757d;
      padding: 1.25rem;
      font-weight: 600;
      border-radius: 0;
      transition: box-shadow 0.2s;
      margin: 0 0 -2px 0;
    }

    .tabview-header-admin.p-tabview .p-tabview-panels {
      padding: 0;
    }

    .tabview-header-admin.p-tabview .p-tabview-nav {
      border: transparent;

      li {
        width: 33.33%;

        span {
          font-size: 16px;
        }

        i {
          margin-right: 10px;
        }
      }
    }

    .tabview-header-admin.p-tabview .p-tabview-nav-container .p-tabview-nav-content {
      border-radius: 6px 6px 0px 0px;
    }

    .tabview-header-admin.p-tabview .p-tabview-nav {

      li.p-highlight .p-tabview-nav-link {
        background: transparent;
        border-color: transparent;
        color: generalStyle.$bitstoneRed
      }

      .p-tabview-ink-bar {
        background-color: generalStyle.$bitstoneRed;
        display: none;
      }
    }

    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
      box-shadow: none;
    }
  }
}