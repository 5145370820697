@use 'src/assets/styles/variables' as generalStyle;
@import 'src/assets/styles/_modal-styles';

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .loader {
    box-sizing: border-box;
    position: relative;
    width: 48px;
    height: 48px;
    animation: spin 1s linear infinite;
  }

  .loader:after,
  .loader:before {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    border-radius: 50%;
    background: #FF3D00;
    animation: spin 1s linear infinite;
    transform-origin: 0px 100%;
  }

  .loader:before {
    transform-origin: 0 50%;
    background: #fff;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

.file-section {
  display: flex;
  flex-direction: column;
  &__container {
    background-color: white;
    border-radius: 6px;

    .row {
      display: flex;
      align-items: center;
      .key {
        color: generalStyle.$labelTextColor;
        font-size: generalStyle.$textFontSize;
        font-family: generalStyle.$fontFamily;
        margin-left: 20px;
        min-width: 80px;
      }
      p {
        margin-right: 1rem;
        color: generalStyle.$textColor;
        font-size: generalStyle.$textFontSize;
        font-family: generalStyle.$fontFamily;
        margin-left: 20px;
        border: none;
        opacity: 1;
        width: 100%;
        padding: 0.25rem;
      }
      img {
        margin-right: 30px;
      }
    }
  }
}

.profile-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;

  .profile-page-container {
    background-color: generalStyle.$backgroundColor;
    position: relative;
    max-width: 1400px;
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 0 20px;
    overflow: initial;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .sidemenu-container .container {
      margin: 7rem 59px 0 0;
    }

    .profile-page__content {
      margin: 0rem 0 0.5rem 0;
      width: 100%;
      height: auto;
      max-height: 100vh;
      overflow: auto;
      display: flex;
      flex-direction: column;

      .line {
        border-bottom: 1px solid generalStyle.$lineColor;
        width: 100%;
      }

      &-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        position: relative;
        margin: 5.2rem 0 0 0;
        align-items: center;

        .flex-1 {
          display: flex;
          flex-direction: row;
          align-items: center;

          button {
            span {
              display: inherit;
              margin-right: 8px;

              svg {
                font-size: 25px;
              }
            }
          }

          p {
            font-size: 30px;
            font-family: generalStyle.$fontFamily;
            color: generalStyle.$textColor;
          }
        }
      }

      .export-btn {
        span {
          display: inherit;
          margin-right: 8px;
          margin-left: 8px;
        }
      }

      &-data {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 2rem;
        padding-bottom: 1rem;
        overflow: hidden;

        &::-webkit-scrollbar {
          display: none;
        }

        .profile-sections {
          height: 100%;
          width: 100%;
          margin-left: 3rem;
          overflow: auto;
          padding-right: 8px;
          @include verticalScrollbar;

          &-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;

            &-name {
              display: flex;
              justify-content: flex-start;
              flex-direction: row;
              align-items: center;

              .profile-name-container {
                display: flex;
                flex-direction: row;
                align-items: center;
              }

              p {
                font-size: 26px;
                font-family: generalStyle.$fontFamily;
                color: generalStyle.$textColor;
                margin: 0;
              }

              svg {
                fill: generalStyle.$bitstoneRed;
                margin-left: 15px;
              }

              .profile-picture-small-res {
                display: none;
              }
            }
          }

          &-basic-user-data {
            width: 100%;
            background-color: #fff;
            border-radius: 6px;
            margin-top: 25px;
            display: flex;
            flex-direction: column;

            .header {
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              align-items: flex-start;
              align-items: center;
              width: 100%;

              p {
                color: generalStyle.$bitstoneRed;
                font-size: 18px;
                font-family: generalStyle.$fontFamily;
                font-weight: 600;
                margin-left: 20px;
              }

              button span svg {
                width: 20px;
              }
            }

            .data-grid {
              display: grid;
              grid-template-columns: 0.75fr 1fr;
              gap: 0px;
              padding: 20px 0;

              .grid-item {
                margin: 5px 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .phone-link {
                  text-decoration: none;
                }

                .key {
                  color: generalStyle.$labelTextColor;
                  font-size: generalStyle.$textFontSize;
                  font-family: generalStyle.$fontFamily;
                  margin-left: 20px;
                  min-width: 80px;
                }

                .value.p-inputtext {
                  margin-right: 1rem;
                  color: generalStyle.$textColor;
                  font-size: generalStyle.$textFontSize;
                  font-family: generalStyle.$fontFamily;
                  margin-left: 20px;
                  border: none;
                  opacity: 1;
                  width: 100%;
                  padding: 0.25rem;
                }
                p {
                  margin-right: 1rem;
                  color: generalStyle.$textColor;
                  font-size: generalStyle.$textFontSize;
                  font-family: generalStyle.$fontFamily;
                  margin-left: 20px;
                  border: none;
                  opacity: 1;
                  width: 100%;
                  padding: 0.25rem;
                }
              }
            }
          }

          &-extra-user-data {
            display: flex;
            height: fit-content;
            flex-direction: column;
            padding-bottom: 5rem;
            height: fit-content;

            .extras-header {
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              align-items: flex-start;
              align-items: center;
              width: 100%;
              margin-top: 40px;

              p {
                color: generalStyle.$textColor;
                font-size: 18px;
                font-family: generalStyle.$fontFamily;
                font-weight: 500;
              }

              button span svg {
                width: 20px;
              }
            }

            .extras-content {
              .noInfo {
                padding: 15px 0 40px 20px;
              }

              .bitstone-info-tab {
                padding-bottom: 1rem;
                padding-top: 20px;

                .data {
                  display: flex;
                  flex-direction: column;
                  margin-right: 3rem;

                  &-item {
                    margin: 10px 0;
                    display: flex;
                    justify-content: flex-start;

                    .textarea {
                      background-color: transparent;
                      text-align: justify;
                      resize: vertical;
                      margin-right: 1rem;
                      color: generalStyle.$textColor;
                      font-size: generalStyle.$textFontSize;
                      font-family: generalStyle.$fontFamily;
                      margin-left: 20px;
                      border: none;
                      opacity: 1;
                      width: 100%;
                      padding-right: 7px;
                    }

                    .key {
                      color: generalStyle.$labelTextColor;
                      font-size: generalStyle.$textFontSize;
                      font-family: generalStyle.$fontFamily;
                      margin-left: 20px;
                      min-width: 180px;
                      padding: 10px 0;
                    }

                    .value.p-inputtext {
                      margin-right: 1rem;
                      color: generalStyle.$textColor;
                      font-size: generalStyle.$textFontSize;
                      font-family: generalStyle.$fontFamily;
                      margin-left: 20px;
                      border: none;
                      opacity: 1;
                      width: 100%;
                      padding: 10px 0;
                      user-select: text;
                    }

                    .value-textarea {
                      height: 115px;
                      background-color: transparent;
                      text-align: justify;
                      resize: vertical;
                      margin-right: 1rem;
                      color: generalStyle.$textColor;
                      font-size: generalStyle.$textFontSize;
                      font-family: generalStyle.$fontFamily;
                      margin-left: 20px;
                      border: none;
                      opacity: 1;
                      width: 100%;
                      overflow-x: hidden;
                      padding: 10px 7px 10px 0;

                      @include verticalScrollbar;
                    }
                  }
                }
              }

              .relevant-info-tab {
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;
                overflow: auto;
                max-height: 600px;

                &::-webkit-scrollbar {
                  display: none;
                }

                .experience-container {
                  display: flex;
                  flex-direction: column;

                  .experience-content {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    margin-top: 15px;
                    margin-bottom: 15px;

                    .left-side {
                      min-width: 210px;
                      margin-left: 20px;
                      margin-right: 1rem;
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;

                      .title {
                        color: generalStyle.$textColor;
                        font-size: generalStyle.$textFontSize;
                        font-weight: 600;
                        margin: 16px 0 12px 0;
                        font-family: generalStyle.$fontFamily;
                        display: flex;
                        justify-content: flex-start;
                      }

                      .title::before {
                        content: '\2022';
                        display: inline-block;
                        margin-right: 5px;
                        font-size: 20px;
                        color: #7f8692;
                      }

                      .sub-title {
                        color: generalStyle.$labelTextColor;
                        font-size: 14px;
                        margin: 5px 0 0 14px;
                        font-family: generalStyle.$fontFamily;
                      }
                    }

                    .right-side {
                      .text {
                        color: generalStyle.$labelTextColor;
                        font-size: 14px;
                        margin: 16px 50px 0 0;
                        font-family: generalStyle.$fontFamily;
                        text-align: justify;
                      }
                    }
                  }

                  .line {
                    border-bottom: 2px solid generalStyle.$lineColor;
                    width: 93%;
                    margin: 10px 0px;
                    align-self: center;
                  }
                }
              }

              .projects-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;
                max-height: 600px;
                -ms-overflow-style: none;
                scrollbar-width: none;
                overflow-y: auto;

                @include verticalScrollbar;

                .each-project {
                  display: flex;
                  flex-direction: column;

                  .project {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    margin-top: 15px;
                    margin-bottom: 15px;

                    .left-side {
                      min-width: 168px;
                      margin-left: 20px;
                      margin-right: 1rem;

                      .title {
                        color: generalStyle.$textColor;
                        font-size: generalStyle.$textFontSize;
                        font-weight: 600;
                        margin: 16px 0 12px 0;
                        font-family: generalStyle.$fontFamily;
                        display: flex;
                        justify-content: flex-start;
                      }
                    }

                    .right-side {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;

                      .text {
                        color: generalStyle.$textColor;
                        font-size: 16px;
                        margin: 16px 50px 0 0;
                        font-family: generalStyle.$fontFamily;
                        text-align: justify;

                        span {
                          color: generalStyle.$labelTextColor;
                          font-family: generalStyle.$fontFamily;
                          font-size: 16px;
                          margin: 0;
                        }
                      }
                    }
                  }

                  .line {
                    border-bottom: 1px solid generalStyle.$lineColor;
                    width: 95%;
                    margin: auto;
                  }
                }
              }

              .personal-info-tab {
                padding-top: 20px;
                .data {
                  display: flex;
                  flex-direction: column;
                  margin-right: 3rem;

                  &-item {
                    margin: 10px 0;
                    display: flex;
                    align-items: flex-start;
                    // align-items: center;

                    .key {
                      color: generalStyle.$labelTextColor;
                      font-size: generalStyle.$textFontSize;
                      font-family: generalStyle.$fontFamily;
                      margin-left: 20px;
                      min-width: 110px;
                      padding: 10px 0;
                    }

                    .textarea {
                      color: generalStyle.$textColor;
                      font-size: generalStyle.$textFontSize;
                      font-family: generalStyle.$fontFamily;
                      border: none;
                      opacity: 1;
                      width: 100%;
                      padding: 0;
                      background-color: #fff;
                      -ms-overflow-style: none;
                      scrollbar-width: none;
                      margin: 0 1rem 0 20px;
                     
                      &.nl {
                        white-space: pre-line;
                        padding: 10px 0;
                      }

                    }

                    .value-textarea {
                      height: 80px;
                      margin-right: 1rem;
                      color: generalStyle.$textColor;
                      font-size: generalStyle.$textFontSize;
                      font-family: generalStyle.$fontFamily;
                      margin-left: 20px;
                      border: none;
                      opacity: 1;
                      width: 100%;
                      padding: 0;
                      overflow: auto;
                      background-color: #fff;
                      resize: none;
                      -ms-overflow-style: none;
                      scrollbar-width: none;
                      overflow-y: auto;
                      overflow-x: hidden;

                      &-2 {
                        height: fit-content;
                        min-height: 60px;
                        margin-right: 1rem;
                        color: generalStyle.$textColor;
                        font-size: generalStyle.$textFontSize;
                        font-family: generalStyle.$fontFamily;
                        margin-left: 20px;
                        border: none;
                        opacity: 1;
                        width: 100%;
                        padding: 0;
                        overflow: auto;
                        background-color: #fff;
                        resize: none;
                        white-space: break-spaces;

                        -ms-overflow-style: none;
                        scrollbar-width: none;
                        overflow-y: auto;
                        overflow-x: hidden;

                        @include verticalScrollbar;
                      }

                      @include verticalScrollbar;
                    }

                    .value.p-inputtext {
                      margin-right: 1rem;
                      color: generalStyle.$textColor;
                      font-size: generalStyle.$textFontSize;
                      font-family: generalStyle.$fontFamily;
                      margin-left: 20px;
                      border: none;
                      opacity: 1;
                      width: 100%;
                      padding: 0;
                      text-align: justify;
                      resize: none;
                      padding: 10px 0;
                    }
                    .value-p {
                      margin: 0 1rem;
                      color: generalStyle.$textColor;
                      font-size: generalStyle.$textFontSize;
                      font-family: generalStyle.$fontFamily;
                      margin-left: 20px;
                      border: none;
                      opacity: 1;
                      width: 100%;
                      padding: 0;
                      text-align: justify;
                      resize: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.extra-details-tabview.p-tabview {
  .p-tabview-panels {
    padding: 0;
  }

  .p-tabview-nav {
    border: transparent;

    li {
      width: 33.33%;

      &.p-highlight .p-tabview-nav-link {
        background: transparent;
        border-color: transparent;

        .p-tabview-title {
          color: generalStyle.$bitstoneRed;
        }
      }
    }

    .p-tabview-ink-bar {
      background-color: generalStyle.$bitstoneRed;
      display: none;
    }

    @media only screen and (max-width: 425px) {
      width: fit-content;
    }
  }

  .p-tabview-nav-container .p-tabview-nav-content {
    border-radius: 6px 6px 0px 0px;
  }

  .p-tabview-nav li .p-tabview-nav-link {
    background: #7F869233;
    font-weight: 700;
    border-radius: 0;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
    color: generalStyle.$labelTextColor;
    font-size: 18px;
    font-family: generalStyle.$fontFamily;
    display: grid;
    justify-content: center;
    border: none;
    height: 55px;

    &:not(.p-disabled):focus {
      box-shadow: none !important;
    }

    @media only screen and (max-width: 769px) {
      text-align: center;
      display: flex;

      span {
        font-size: 12px;
        white-space: break-spaces;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 1440px) {
      text-align: center;
      display: flex;

      span {
        font-size: 14px;
        white-space: break-spaces;
      }
    }
  }
}

.cv-modal.p-dialog {
  @include modalCommonStyles;

  .p-dialog-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: auto;
    padding: 0;

    &::-webkit-scrollbar {
      height: 2px;
      background-color: #e6e6e6;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background: generalStyle.$bitstoneRed;
      border-radius: 12px;
    }

    @include verticalScrollbar;
  }
}

.deleteModal.p-dialog {
  @include modalCommonStyles;

  .pi.pi-times {
    font-size: 16px;
  }

  .p-dialog-content {
    padding: 1rem 1.5rem;

    .confirmation-content {
      display: flex;
      align-items: center;
    }
  }

  .p-dialog-footer {
    height: 50px;

    button span {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 425px) {
  .profile-page {
    .profile-page-container {
      .sidemenu {
        display: none;
      }

      .profile-page__content {
        margin: 0;
        height: 100vh;

        &-actions {
          margin: 3rem 0 0 0;

          .flex-1 {
            p {
              margin-left: -10px;
              font-size: 25px;
            }

            button span svg {
              font-size: 22px;
            }
          }

          .export-btn {
            display: flex;
            justify-content: center;
            font-size: 0;
            border: transparent;
            width: 43px;
            min-width: 40px;

            span {
              display: inherit;
              margin-right: 8px;
              margin-left: 8px;
            }
          }
        }

        &-data {
          flex-direction: column;
          margin-top: 1rem;

          .profile-image {
            display: none;
          }

          .profile-sections {
            margin: 0;

            &-top {
              margin-bottom: 1rem;
              &-name {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: center;
                width: 100vw;

                .profile-name-container {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  p {
                    font-size: 20px;
                    font-family: generalStyle.$fontFamily;
                    color: generalStyle.$textColor;
                    margin: 0;
                  }
                }

                .profile-picture-small-res {
                  display: block;
                }

                svg {
                  fill: generalStyle.$bitstoneRed;
                  margin-left: 15px;
                  width: 16px;
                }
              }

              .action-btn {
                button svg {
                  margin-left: 24px;
                }
              }
            }

            &-basic-user-data {
              margin-top: 0;
              .header {
                p {
                  font-size: 14px;
                }

                .edit-btn {
                  font-size: 14px;
                }

                button span svg {
                  width: 14px;
                }
              }

              .data-grid {
                grid-template-columns: 1fr;
                gap: 0;
                padding: 0;

                .grid-item {
                  .key {
                    font-size: 15px;
                    min-width: 25%;
                  }

                  .value.p-inputtext {
                    font-size: 14px;
                  }
                  p {
                    font-size: 14px;
                    padding: 0 0.25rem;
                  }
                }
              }
            }

            &-extra-user-data {
              .extras-header {
                p {
                  font-size: 14px;
                }

                .edit-btn {
                  font-size: 14px;
                }

                button span svg {
                  width: 14px;
                }
              }

              .extras-content {
                .noInfo {
                  padding: 9px 0 12px 4px;
                  font-size: 14px;
                }

                .bitstone-info-tab {
                  padding-bottom: 0;
                  .data {
                    margin: 0;

                    &-item {
                      margin: 5px 0;

                      .key {
                        font-size: 14px;
                        min-width: 28%;
                      }

                      .textarea {
                        background-color: transparent;
                        text-align: justify;
                        resize: vertical;
                        margin-right: 1rem;
                        color: generalStyle.$textColor;
                        font-family: generalStyle.$fontFamily;
                        margin-left: 20px;
                        border: none;
                        opacity: 1;
                        width: 100%;
                        padding-right: 7px;
                        font-size: 14px;
                      }

                      .value.p-inputtext {
                        font-size: 14px;
                      }

                      .value-textarea {
                        font-size: 14px;
                        @include verticalScrollbar;
                      }
                    }
                  }
                }

                .relevant-info-tab {
                  .experience-container {
                    .experience-content {
                      flex-direction: column;
                      margin-top: 0;

                      .left-side {

                        .title,
                        .sub-title {
                          font-size: 15px;
                        }
                      }

                      .right-side {
                        margin-left: 20px;

                        .text {
                          margin: 5px 30px 0 14px;
                        }
                      }
                    }
                  }
                }

                .projects-container {
                  .each-project {
                    .project {
                      flex-direction: column;

                      .left-side {
                        .title {
                          color: generalStyle.$textColor;
                          font-size: 15px;
                          font-weight: 600;
                          margin: 5px 0 12px 0;
                          font-family: generalStyle.$fontFamily;
                          display: flex;
                          justify-content: flex-start;
                        }
                      }

                      .right-side {
                        margin-left: 20px;
                        margin-right: 1rem;

                        .text {
                          font-size: 14px;
                          margin: 13px 12px 0 0;
                        }
                      }
                    }
                  }
                }

                .personal-info-tab {
                  .data {
                    margin: 0;

                    &-item {
                      margin: 5px 0;
                      align-items: center;

                      .key {
                        font-size: 14px;
                        min-width: 20%;
                      }

                      .value-textarea {
                        font-size: 14px;

                        &-2 {
                          font-size: 14px;
                        }
                      }

                      .value.p-inputtext {
                        font-size: 14px;
                        padding: 10px 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .cv-modal.p-dialog {
    width: 90%;
    .p-dialog-content {
      overflow-x: auto;
      padding: 0;

      &::-webkit-scrollbar {
        height: 2px;
        background-color: #e6e6e6;
        border-radius: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background: generalStyle.$bitstoneRed;
        border-radius: 12px;
      }
    }

    .p-dialog-footer {
      height: 40px;

      button {
        background-color: transparent;
        border: transparent;
        color: generalStyle.$bitstoneRed;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-width: 425px) and (max-width: 769px) {
  .profile-page {
    .profile-page-container {
      .sidemenu {
        display: none;
      }

      .profile-page__content {
        margin: 0;
        height: 100vh;

        &-actions {
          margin: 3.3rem 0 0 0;

          .flex-1 {
            p {
              margin-left: -10px;
              font-size: 25px;
            }

            button span svg {
              font-size: 22px;
            }
          }

          .export-btn {
            display: flex;
            justify-content: center;
            font-size: 0;
            border: transparent;
            width: 43px;
            min-width: 40px;

            span {
              display: inherit;
              margin-right: 8px;
              margin-left: 8px;
            }
          }
        }

        &-data {
          flex-direction: column;
          margin-top: 1rem;

          .profile-image {
            display: none;
          }

          .profile-sections {
            margin: 0;

            &-top {
              margin-bottom: 1rem;
              &-name {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: center;
                width: 100vw;

                .profile-name-container {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  p {
                    font-size: 23px;
                    font-family: generalStyle.$fontFamily;
                    color: generalStyle.$textColor;
                    margin: 0;
                  }
                }

                svg {
                  fill: generalStyle.$bitstoneRed;
                  margin-left: 15px;
                  width: 18px;
                }

                .profile-picture-small-res {
                  display: block;
                }
              }

              .action-btn {
                margin-left: 15px;
              }
            }

            &-basic-user-data {
              margin-top: 0;
              .header {
                p {
                  font-size: 16px;
                }

                .edit-btn {
                  font-size: 16px;
                }

                button span svg {
                  width: 16px;
                }
              }

              .data-grid {
                grid-template-columns: 1fr;
                gap: 0;
                padding: 0;

                .grid-item {
                  .key {
                    font-size: 15px;
                    min-width: 25%;
                  }

                  .value.p-inputtext {
                    font-size: 15px;
                    color: generalStyle.$textColor;
                    font-family: generalStyle.$fontFamily;
                  }

                  p {
                    font-size: 15px;
                    padding: 0 0.25rem;
                  }
                }
              }
            }

            &-extra-user-data {
              .extras-header {
                p {
                  font-size: 16px;
                }

                .edit-btn {
                  font-size: 16px;
                }

                button span svg {
                  width: 16px;
                }
              }

              .extras-content {
                .noInfo {
                  padding: 9px 0 12px 4px;
                  font-size: 14px;
                }

                .bitstone-info-tab {
                  padding-bottom: 0;

                  .data {
                    margin: 0;
                    padding: 0 0 1rem 0;

                    &-item {
                      margin: 5px 0;

                      .key {
                        font-size: 14px;
                        min-width: 25%;
                      }

                      .textarea {
                        background-color: transparent;
                        text-align: justify;
                        resize: vertical;
                        margin-right: 1rem;
                        color: generalStyle.$textColor;
                        font-size: generalStyle.$textFontSize;
                        font-family: generalStyle.$fontFamily;
                        margin-left: 20px;
                        border: none;
                        opacity: 1;
                        width: 100%;
                        padding-right: 7px;
                        font-size: 14px;
                      }

                      .value.p-inputtext {
                        font-size: 14px;
                      }

                      .value-textarea {
                        font-size: 14px;
                        @include verticalScrollbar;
                      }
                    }
                  }
                }

                .relevant-info-tab {
                  .experience-container {
                    .experience-content {
                      flex-direction: column;
                      margin-top: 0;

                      .left-side {

                        .title,
                        .sub-title {
                          font-size: 15px;
                        }
                      }

                      .right-side {
                        margin-left: 20px;

                        .text {
                          margin: 5px 30px 0 14px;
                        }
                      }
                    }
                  }
                }

                .projects-container {
                  .each-project {
                    .project {
                      flex-direction: column;

                      .left-side {
                        .title {
                          color: generalStyle.$textColor;
                          font-size: 15px;
                          font-weight: 600;
                          margin: 5px 0 12px 0;
                          font-family: generalStyle.$fontFamily;
                          display: flex;
                          justify-content: flex-start;
                        }
                      }

                      .right-side {
                        margin-left: 20px;
                        margin-right: 1rem;

                        .text {
                          font-size: 14px;
                          margin: 13px 12px 0 0;
                        }
                      }
                    }
                  }
                }

                .personal-info-tab {
                  .data {
                    margin: 0;

                    &-item {
                      margin: 5px 0;
                      align-items: center;

                      .key {
                        font-size: 14px;
                        min-width: 85px;
                      }

                      .value-textarea {
                        font-size: 14px;

                        &-2 {
                          font-size: 14px;
                        }
                      }

                      .value.p-inputtext {
                        font-size: 14px;
                        padding: 10px 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .cv-modal.p-dialog {
    width: 90%;
    .p-dialog-footer {
      height: 50px;

      button {
        background-color: transparent;
        border: transparent;
        color: generalStyle.$bitstoneRed;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .profile-page {
    .profile-page-container {
      .sidemenu {
        display: none;
      }

      .sidemenu-container .container {
        margin: 7rem 25px 0 0;
      }

      .profile-page__content {
        overflow: auto;
        margin: 0;

        &-actions {
          margin: 5.2rem 0 0 0;

          .export-btn {
            display: flex;
            justify-content: center;
            font-size: 0;
            border: transparent;
            width: 43px;
            min-width: 40px;

            span {
              display: inherit;
              margin-right: 8px;
              margin-left: 8px;
            }
          }
        }

        &-data {
          .profile-sections {
            overflow: auto;
            margin-left: 2rem;
            height: 100%;

            &::-webkit-scrollbar {
              display: none;
            }

            &-top {
              &-name {
                .profile-name-container {
                  display: flex;
                  align-items: center;
                }
              }
            }

            &-basic-user-data {
              .header {
                p {
                  font-size: 16px;
                }

                .edit-btn {
                  font-size: 15px;
                }

                .action-btn {
                  margin-left: 10px;
                }

                button span svg {
                  width: 15px;
                }
              }

              .data-grid {
                grid-template-columns: 0.75fr 1fr;
                gap: 16px;

                .grid-item {
                  .key {
                    font-size: 15px;
                    min-width: 20%;
                  }

                  .value.p-inputtext {
                    font-size: 15px;
                  }
                  
                  p {
                    font-size: 15px;
                    padding: 0 0.25rem;
                  }
                }
              }
            }

            &-extra-user-data {
              .extras-header {
                p {
                  font-size: 14px;
                }

                .edit-btn {
                  font-size: 14px;
                }

                button span svg {
                  width: 14px;
                }
              }

              .extras-content {
                .bitstone-info-tab {
                  padding-top: 20px;

                  .data {
                    margin-right: 0;

                    &-item {
                      .key {
                        font-size: 15px;
                      }

                      .textarea {
                        font-size: 15px;
                      }

                      .value.p-inputtext {
                        font-size: 15px;
                      }

                      .value-textarea {
                        font-size: 15px;
                        @include verticalScrollbar;
                      }
                    }
                  }
                }

                .relevant-info-tab {
                  padding-top: 20px;

                  .experience-container {
                    .experience-content {
                      flex-direction: row;
                      margin-top: 0;

                      .left-side {

                        .title,
                        .sub-title {
                          font-size: 15px;
                        }
                      }

                      .right-side {
                        margin-left: 20px;

                        .text {
                          margin: 16px 50px 0 0;
                        }
                      }
                    }
                  }
                }

                .projects-container {
                  .each-project {
                    .project {
                      flex-direction: column;

                      .left-side {
                        .title {
                          color: generalStyle.$textColor;
                          font-size: 15px;
                          font-weight: 600;
                          margin: 5px 0 12px 0;
                          font-family: generalStyle.$fontFamily;
                          display: flex;
                          justify-content: flex-start;
                        }
                      }

                      .right-side {
                        margin-left: 20px;
                        margin-right: 1rem;

                        .text {
                          font-size: 14px;
                          margin: 13px 12px 0 0;
                        }
                      }
                    }
                  }
                }

                .personal-info-tab {
                  padding-top: 20px;

                  .data {
                    margin: 0;

                    &-item {
                      margin: 5px 0;
                      align-items: center;

                      .key {
                        font-size: 15px;
                        min-width: 20%;
                      }

                      .value-textarea {
                        font-size: 15px;

                        &-2 {
                          font-size: 15px;
                        }
                      }

                      .value.p-inputtext {
                        font-size: 15px;
                        padding: 10px 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .profile-page {
    .profile-page-container {
      .sidemenu {
        display: none;
      }
      
      .sidemenu-container .container {
        margin: 7rem 25px 0 0;
      }

      .profile-page__content {
        overflow: hidden;
        margin: 0 0 0 30px;

        &-actions {
          margin: 5.2rem 0 0 0;

          .export-btn {
            width: 165px;
            height: 40px;
            font-size: 14px;
            line-height: 10px;
            justify-content: center;
            border: transparent;
          }
        }

        &-data {
          .profile-sections {
            overflow: auto;
            margin-left: 2rem;
            height: 100%;
            &::-webkit-scrollbar {
              display: none;
            }

            &-top {
              &-name {
                .profile-name-container {
                  display: flex;
                  align-items: center;
                }
              }
            }

            &-basic-user-data {
              .header {
                p {
                  font-size: 16px;
                }

                .edit-btn {
                  font-size: 15px;
                }

                .action-btn {
                  margin-left: 10px;
                }

                button span svg {
                  width: 15px;
                }
              }

              .data-grid {
                grid-template-columns: 0.75fr 1fr;
                gap: 0px;

                .grid-item {
                  .key {
                    font-size: 15px;
                    min-width: 20%;
                  }

                  .value.p-inputtext {
                    font-size: 15px;
                  }
                  
                  p {
                    font-size: 15px;
                    padding: 0 0.25rem;
                  }
                }
              }
            }

            &-extra-user-data {
              .extras-header {
                p {
                  font-size: 14px;
                }

                .edit-btn {
                  font-size: 14px;
                }

                button span svg {
                  width: 14px;
                }
              }

              .extras-content {
                .bitstone-info-tab {
                  padding-top: 20px;

                  .data {
                    margin-right: 0;

                    &-item {
                      .key {
                        font-size: 15px;
                      }

                      .textarea {
                        font-size: 15px;
                      }

                      .value.p-inputtext {
                        font-size: 15px;
                      }

                      .value-textarea {
                        font-size: 15px;
                        @include verticalScrollbar;
                      }
                    }
                  }
                }

                .relevant-info-tab {
                  padding-top: 20px;

                  .experience-container {
                    .experience-content {
                      flex-direction: row;
                      margin-top: 0;

                      .left-side {

                        .title,
                        .sub-title {
                          font-size: 15px;
                        }
                      }

                      .right-side {
                        margin-left: 20px;

                        .text {
                          margin: 16px 50px 0 0;
                        }
                      }
                    }
                  }
                }

                .projects-container {
                  .each-project {
                    .project {
                      flex-direction: column;

                      .left-side {
                        .title {
                          color: generalStyle.$textColor;
                          font-size: 15px;
                          font-weight: 600;
                          margin: 5px 0 12px 0;
                          font-family: generalStyle.$fontFamily;
                          display: flex;
                          justify-content: flex-start;
                        }
                      }

                      .right-side {
                        margin-left: 20px;
                        margin-right: 1rem;

                        .text {
                          font-size: 14px;
                          margin: 13px 12px 0 0;
                        }
                      }
                    }
                  }
                }

                .personal-info-tab {
                  padding-top: 20px;

                  .data {
                    margin: 0;

                    &-item {
                      margin: 5px 0;
                      align-items: center;

                      .key {
                        font-size: 15px;
                        min-width: 20%;
                      }
                      .textarea {
                        line-height: 25px;
                        font-size: 15px;
                      }

                      .value-textarea {
                        font-size: 15px;

                        &-2 {
                          font-size: 15px;
                        }
                      }

                      .value.p-inputtext {
                        font-size: 15px;
                        padding: 10px 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}