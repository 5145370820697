@use 'src/assets/styles/variables' as generalStyle;

#pdf-content {
  display: inline-block;

  .cv-container {
    display: flex;
    flex-direction: row;
    height: 297mm;
    width: 210mm;

    .left-side {
      display: flex;
      flex-direction: column;

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 2rem 0 1rem 0;
        svg {
          width: 55%;
        }
      }

      .profile-image {
        display: flex;
        justify-content: flex-start;
        width: 202px;
        background-color: white;
        margin: 10px 20px;
        border-radius: 6px;
        height: 260px;
        flex-direction: column;
        align-items: center;

        .image {
          display: flex;
          justify-content: center;
          width: 200px;

          .img {
            width: 203px;
            height: 160px;
            object-fit: cover;
            margin: 10px;
            border-radius: 6px;
          }
        }

        .title {
          font-size: 20px;
          font-family: generalStyle.$fontFamily;
          color: generalStyle.$textColor;
          margin: 6px 0 -5px 0;
        }

        .job {
          font-size: 16px;
          font-family: generalStyle.$fontFamily;
          color: generalStyle.$textColor;
          font-weight: 600;
          margin: 6px 0 -5px 0;
        }
      }

      .skills,
      .education,
      .languages {
        display: flex;
        flex-direction: column;
        margin: 10px 20px;

        h4 {
          color: #fff;
          margin: 10px 0 -5px 0;
          font-weight: 600;
        }

        ul {
          margin-left: -20px;
          list-style-type: none;

          li {
            position: relative;
            color: #fff;
            font-size: 14px;

            &::before {
              content: "";
              position: absolute;
              left: -15px;
              top: 50%;
              transform: translateY(-50%);
              width: 6px;
              height: 6px;
              background-color: #fff;
              border-radius: 50%;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 89px 35px 10px 46px;
    width: 100%;

    .section {
      margin-bottom: 15px;

      .headings {
        display: flex;
        flex-direction: row;
        width: fit-content;
        margin-left: -46px;
        align-items: center;

        .line {
          width: 30px;
          height: 1px;
          background-color: generalStyle.$bitstoneRed;
          margin-right: 14px;
          border-color: #ec1c24;
        }

        label {
          font-size: 18px;
          font-family: generalStyle.$fontFamily;
          color: generalStyle.$textColor;
          font-weight: 600;
        }
      }

      p {
        font-size: 14px;
        font-family: generalStyle.$fontFamily;
        color: generalStyle.$textColor;
        text-align: justify;
      }

      .project {
        h3 {
          color: generalStyle.$bitstoneRed;
          font-family: generalStyle.$fontFamily;
          margin: 23px 0 5px 0;
        }
      }

      span {
        color: generalStyle.$labelTextColor;
        font-family: generalStyle.$fontFamily;
      }
    }
  }
}
