@use 'src/assets/styles/variables' as generalStyle;

.image__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-basis: 20%;

    &.edit-mode {
        margin-right: -18px;
    }

    img {
        height: 160px;
        width: 160px;
        object-fit: cover;
        border-radius: 6px;
        object-position: 0% 25%;
    }

    button span svg {
        width: 20px;
    }

    .buttons {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
    }
}

.crop-dialog.p-dialog {
    box-shadow: none;
    display: flex;

    .p-dialog-header {
        box-shadow: 0px 6px 6px generalStyle.$shadowColor;
        z-index: 500;
        border-radius: 6px 6px 0px 0px;
        height: 54px;

        .p-dialog-title {
            font-weight: 700;
            font-size: 20px;
            color: generalStyle.$textColor;
            font-family: generalStyle.$fontFamily
        }

        .p-dialog-header-icon {
            box-shadow: 0 0 0 0 transparent;
            outline: none !important;
            transition: .5s ease-in-out;
            position: absolute;
            box-shadow: 0 0 0 0 transparent !important;
            right: 14px;
            top: 12px;

            &:hover {
                background-color: #fff !important;
                color: generalStyle.$bitstoneRed !important;
                rotate: 3.1416rad;
                box-shadow: 0 0 0 0 transparent !important;
                outline: none !important;
            }

            &:active {
                background-color: #fff !important;
                color: generalStyle.$bitstoneRed !important;
                box-shadow: 0 0 0 0 transparent !important;
                outline: none !important;
            }
        }
    }

    .p-dialog-content {
        overflow: hidden;
        height: 670px;

        .crop-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            
            img {
                max-width: 400px; 
                max-height: 600px; 
                display: block;
                margin: 0 auto; /* centers the image if smaller than the container */
            }

            .crop-button {
                box-shadow: none;
                text-transform: none;
                font-size: 18px;
                border: none;
                line-height: 26px;
                background-color: #fff;
                border-color: none;
                color: generalStyle.$textColor;
                font-family: generalStyle.$fontFamily;
                height: 40px;
                cursor: pointer;
                margin: -9px 0;

                &:hover {
                    background-color: #fff;
                    color: generalStyle.$bitstoneRed;
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .image__container {
        &.edit-mode {
            margin-right: 10px;

            .buttons button {
                font-size: 0px;
                min-width: 0;
                margin: 0;
                padding: 5px 0 0 0;
            }
        }

        img {
            height: 80px;
            width: 75px;
            object-fit: cover;
            border-radius: 6px;
        }

        button span svg {
            width: 20px;
        }

        .buttons button {
            font-size: 0px;
            min-width: 0;
            margin: 0;
            padding: 5px 0 0 0;
        }
    }
}
@media only screen and (min-width: 425px) and (max-width: 768px){
    .image__container {
        &.edit-mode {
            margin-right: 10px;

            .buttons button {
                font-size: 0px;
                min-width: 0;
                margin: 0;
                padding: 5px 0 0 0;
            }
        }

        img {
            height: 140px;
            width: 130px;
            object-fit: cover;
            border-radius: 6px;
        }

        button span svg {
            width: 20px;
        }

        .buttons button {
            font-size: 0px;
            min-width: 0;
            margin: 0;
            padding: 5px 0 0 0;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .image__container {
        &.edit-mode {
            margin-right: 10px;
            margin-left: 5px;

            .buttons button {
                font-size: 0px;
                min-width: 36px;
                margin: 0;
                padding: 5px 0 0 0;
            }
        }

        img {
            height: 131px;
            width: 133px;
            object-fit: cover;
            border-radius: 6px;
        }

        button span svg {
            width: 20px;
        }

        .buttons button {
            font-size: 14px;
            min-width: 0;
            margin: 0;
            padding: 5px 0 0 0;
        }
    }
}