.evaluations-wrapper{
    width: 100%;

    button {
        margin: 0 0 0 15px;
        padding: 5px 10px;
    }
    @media only screen and (max-width: 850px) {
        button {
            font-size: 12px;
            margin: 0 0 0 10px;
        }
    }
    @media only screen and (max-width: 767px) {
        button {
            font-size: 0;
            padding: 5px 0;
            margin: 0 0 0 10px;
            min-width: 35px;
            span {
                margin: 0;
            }
        }
    }
    .evaluations-filters {
        padding: 0 20px 12px 20px;
        max-width: 1400px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}